import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { useToast } from '@/components/ui/use-toast'

interface Image {
  id: number
  url: string
}

interface LeisureImageManagerProps {
  images: Image[]
  leisureId: number
  onImageDeleted: () => void
}

export function LeisureImageManager({
  images,
  onImageDeleted,
}: LeisureImageManagerProps) {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [selectedImageId, setSelectedImageId] = useState<number | null>(null)
  const [previewImage, setPreviewImage] = useState<string | null>(null)
  const { toast } = useToast()

  const confirmDelete = (id: number) => {
    setSelectedImageId(id)
    setConfirmOpen(true)
  }

  const handleDelete = async () => {
    if (selectedImageId === null) return
    try {
      await fetch(
        `${import.meta.env.VITE_URL_ENDPOINT}/api/upload/files/${selectedImageId}`,
        { method: 'DELETE' },
      )
      toast({ title: 'Sucesso!', description: 'Imagem de lazer excluída.' })
      onImageDeleted()
    } catch {
      toast({
        title: 'Erro',
        description: 'Erro ao deletar imagem de lazer',
        variant: 'destructive',
      })
    } finally {
      setConfirmOpen(false)
    }
  }

  return (
    <>
      <div className="grid grid-cols-4 gap-4">
        {images.map((image) => (
          <div key={image.id} className="relative">
            <img
              src={image.url}
              alt={`Imagem de lazer ${image.id}`}
              className="h-32 w-full rounded-md object-cover"
            />
            <button
              className="absolute right-2 top-2 rounded bg-white p-1 text-red-500"
              onClick={() => confirmDelete(image.id)}
              aria-label="Excluir imagem de lazer"
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
            <button
              className="absolute bottom-2 right-2 rounded bg-white p-1 text-gray-700"
              onClick={() => setPreviewImage(image.url)}
              aria-label="Visualizar imagem de lazer"
            >
              <FontAwesomeIcon icon={faEye} />
            </button>
          </div>
        ))}
      </div>

      <AlertDialog open={confirmOpen} onOpenChange={setConfirmOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Excluir imagem?</AlertDialogTitle>
            <AlertDialogDescription>
              Esta ação não poderá ser desfeita.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancelar</AlertDialogCancel>
            <AlertDialogAction onClick={handleDelete}>
              Excluir
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {previewImage && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
          onClick={() => setPreviewImage(null)}
        >
          <img
            src={previewImage}
            alt="Preview da imagem de lazer"
            className="max-h-[80vh] max-w-[80vw] object-contain"
          />
        </div>
      )}
    </>
  )
}
