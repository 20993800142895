// House.tsx

import React from 'react'

import { HouseData } from '@/components/form-property/property-types'
import { Checkbox } from '@/components/ui/checkbox'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

interface HouseProps {
  data: HouseData
  onChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => void
  onCheckboxChange: (
    name: keyof HouseData,
    checked: boolean | 'indeterminate',
  ) => void
}

export function House({ data, onChange, onCheckboxChange }: HouseProps) {
  const handleSelectChange = (field: keyof HouseData, value: string) => {
    const event = {
      target: {
        name: field,
        value,
      },
    } as React.ChangeEvent<HTMLInputElement>
    onChange(event)
  }

  return (
    <div className="flex h-full w-full flex-col bg-gray-100 p-8">
      <h1 className="mb-6 text-center text-3xl font-bold text-gray-800">
        Detalhes da Casa
      </h1>

      <div className="rounded-lg bg-white p-6 shadow-md">
        <div>
          <Label htmlFor="type" className="text-gray-600">
            Classificação
          </Label>
          <Select
            value={data.type || ''}
            onValueChange={(value: string) => handleSelectChange('type', value)}
          >
            <SelectTrigger
              id="type"
              name="type"
              className="mb-4 mt-1 w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:w-1/2 md:w-1/4"
            >
              <SelectValue placeholder="Classificação" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Casa">Casa</SelectItem>
              <SelectItem value="Sobrado">Sobrado</SelectItem>
              <SelectItem value="Duplex">Duplex</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          <div>
            <Label htmlFor="numberOfBedrooms" className="text-gray-600">
              Dormitório(s)
            </Label>
            <Input
              type="text"
              id="numberOfBedrooms"
              name="numberOfBedrooms"
              placeholder="Dormitórios"
              value={data.numberOfBedrooms}
              onChange={onChange}
              className="mt-1 block w-full"
            />
          </div>

          <div>
            <Label htmlFor="numberOfSuites" className="text-gray-600">
              Sendo Suíte
            </Label>
            <Input
              type="text"
              id="numberOfSuites"
              name="numberOfSuites"
              placeholder="Sendo Suíte"
              value={data.numberOfSuites}
              onChange={onChange}
              className="mt-1 block w-full"
            />
          </div>

          <div>
            <Label htmlFor="livingRoom" className="text-gray-600">
              Salas(s)
            </Label>
            <Input
              type="text"
              id="livingRoom"
              name="livingRoom"
              placeholder="Sala de Estar"
              value={data.livingRoom}
              onChange={onChange}
              className="mt-1 block w-full"
            />
          </div>

          <div>
            <Label htmlFor="socialBathroom" className="text-gray-600">
              Banheiro Social
            </Label>
            <Input
              type="text"
              id="socialBathroom"
              name="socialBathroom"
              placeholder="Banheiro Social"
              value={data.socialBathroom}
              onChange={onChange}
              className="mt-1 block w-full"
            />
          </div>

          <div>
            <Label htmlFor="halfBath" className="text-gray-600">
              Lavabo
            </Label>
            <Input
              type="text"
              id="halfBath"
              name="halfBath"
              placeholder="Lavabo"
              value={data.halfBath}
              onChange={onChange}
              className="mt-1 block w-full"
            />
          </div>

          <div>
            <Label htmlFor="kitchen" className="text-gray-600">
              Cozinha(s)
            </Label>
            <Input
              type="text"
              id="kitchen"
              name="kitchen"
              placeholder="Cozinha"
              value={data.kitchen}
              onChange={onChange}
              className="mt-1 block w-full"
            />
          </div>

          <div>
            <Label htmlFor="laundryRoom" className="text-gray-600">
              Lavanderia(s)
            </Label>
            <Input
              type="text"
              id="laundryRoom"
              name="laundryRoom"
              placeholder="Lavanderia"
              value={data.laundryRoom}
              onChange={onChange}
              className="mt-1 block w-full"
            />
          </div>

          <div>
            <Label htmlFor="pantry" className="text-gray-600">
              Despensa(s)
            </Label>
            <Input
              type="text"
              id="pantry"
              name="pantry"
              placeholder="Despensa"
              value={data.pantry}
              onChange={onChange}
              className="mt-1 block w-full"
            />
          </div>

          <div>
            <Label htmlFor="closet" className="text-gray-600">
              Closet(s)
            </Label>
            <Input
              type="text"
              id="closet"
              name="closet"
              placeholder="Closet"
              value={data.closet}
              onChange={onChange}
              className="mt-1 block w-full"
            />
          </div>

          <div>
            <Label htmlFor="garage" className="text-gray-600">
              Vaga(s) de Garagem
            </Label>
            <Input
              type="text"
              id="garage"
              name="garage"
              placeholder="Garagem"
              value={data.garage}
              onChange={onChange}
              className="mt-1 block w-full"
            />
          </div>
        </div>

        <h2 className="mb-4 mt-6 text-xl font-semibold text-gray-700">
          Adicionais
        </h2>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          <div className="flex items-center space-x-2">
            <Checkbox
              id="intercom"
              name="intercom"
              checked={data.intercom}
              onCheckedChange={(checked) =>
                onCheckboxChange('intercom', checked)
              }
            />
            <Label htmlFor="intercom" className="text-gray-600">
              Interfone
            </Label>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="maidsQuarters"
              name="maidsQuarters"
              checked={data.maidsQuarters}
              onCheckedChange={(checked) =>
                onCheckboxChange('maidsQuarters', checked)
              }
            />
            <Label htmlFor="maidsQuarters" className="text-gray-600">
              Dependência de empregado
            </Label>
          </div>

          <div className="flex items-center space-x-2">
            <Checkbox
              id="WCEmployee"
              name="WCEmployee"
              checked={data.WCEmployee}
              onCheckedChange={(checked) =>
                onCheckboxChange('WCEmployee', checked)
              }
            />
            <Label htmlFor="maidsQuarters" className="text-gray-600">
              WC de empregado
            </Label>
          </div>
        </div>
      </div>
    </div>
  )
}
