import { useRef, useState } from 'react'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useToast } from '@/components/ui/use-toast'

interface CondominiumLeisureImageUploadProps {
  leisureId: number
  existingImagesCount: number
  onUploadComplete?: () => void
}

export function CondominiumLeisureImageUpload({
  leisureId,
  existingImagesCount,
  onUploadComplete,
}: CondominiumLeisureImageUploadProps) {
  const { toast } = useToast()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [uploading, setUploading] = useState(false)

  const handleUpload = async () => {
    if (!fileInputRef.current?.files) return

    const filesArray = Array.from(fileInputRef.current.files)
    if (filesArray.length + existingImagesCount > 20) {
      toast({
        title: 'Erro',
        description: 'Você só pode ter até 20 imagens de lazer.',
        variant: 'destructive',
      })
      return
    }

    setUploading(true)

    const formData = new FormData()
    filesArray.forEach((file) => formData.append('files', file))

    // Configura a referência para o modelo de lazer do condomínio
    formData.append('ref', 'api::leisure.leisure')
    formData.append('refId', String(leisureId))
    formData.append('field', 'photos')

    try {
      const res = await fetch(
        `${import.meta.env.VITE_URL_ENDPOINT}/api/upload`,
        {
          method: 'POST',
          body: formData,
        },
      )
      if (!res.ok) throw new Error('Upload falhou')
      toast({
        title: 'Sucesso!',
        description: 'Imagens de lazer adicionadas com sucesso.',
      })
      if (onUploadComplete) onUploadComplete()
    } catch (error) {
      toast({
        title: 'Erro no upload',
        description: 'Verifique as imagens e tente novamente.',
        variant: 'destructive',
      })
    } finally {
      setUploading(false)
      if (fileInputRef.current) fileInputRef.current.value = ''
    }
  }

  return (
    <div className="mt-6">
      <Label className="font-semibold text-gray-700">
        Adicionar Imagens de Lazer (Máximo 20)
      </Label>
      <Input
        ref={fileInputRef}
        type="file"
        multiple
        accept="image/*"
        className="mt-2"
      />
      <Button onClick={handleUpload} disabled={uploading} className="mt-2">
        {uploading ? 'Enviando...' : 'Enviar Imagens'}
      </Button>
    </div>
  )
}
