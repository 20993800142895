// Area.tsx

import React from 'react'

import { TerrainData } from '@/components/form-property/property-types'
import { Checkbox } from '@/components/ui/checkbox'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'

interface TerrainProps {
  data: TerrainData
  onChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => void
  onCheckboxChange: (
    name: keyof TerrainData,
    checked: boolean | 'indeterminate',
  ) => void
}

export function Terrain({ data, onChange, onCheckboxChange }: TerrainProps) {
  return (
    <div className="flex h-full w-full flex-col bg-gray-100 p-8">
      <h1 className="mb-6 text-center text-3xl font-bold text-gray-800">
        Detalhes do Terreno
      </h1>

      <div className="rounded-lg bg-white p-6 shadow-md">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          <div>
            <Label htmlFor="front" className="text-gray-600">
              Loteamento
            </Label>
            <Input
              type="text"
              id="loteamento"
              name="loteamento"
              placeholder="loteamento"
              value={data.loteamento}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div>
            <Label htmlFor="front" className="text-gray-600">
              Lote
            </Label>
            <Input
              type="text"
              id="lote"
              name="lote"
              placeholder="lote"
              value={data.lote}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          <div>
            <Label htmlFor="front" className="text-gray-600">
              Meio de Quadra
            </Label>
            <Input
              type="text"
              id="meioDeQuadra"
              name="meioDeQuadra"
              placeholder="Meio de Quadra"
              value={data.meioDeQuadra}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div>
            <Label htmlFor="front" className="text-gray-600">
              Quadra
            </Label>
            <Input
              type="text"
              id="quadra"
              name="quadra"
              placeholder="Quadra"
              value={data.quadra}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
        </div>

        <h2 className="mb-4 mt-6 text-xl font-semibold text-gray-700">
          Dimensões
        </h2>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          <div>
            <Label htmlFor="front" className="text-gray-600">
              Frente (m)
            </Label>
            <Input
              type="text"
              id="front"
              name="front"
              placeholder="Frente (m)"
              value={data.front}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div>
            <Label htmlFor="back" className="text-gray-600">
              Fundo (m)
            </Label>
            <Input
              type="text"
              id="back"
              name="back"
              placeholder="Fundo (m)"
              value={data.back}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div>
            <Label htmlFor="rightSide" className="text-gray-600">
              Lado Direito (m)
            </Label>
            <Input
              type="text"
              id="rightSide"
              name="rightSide"
              placeholder="Lado Direito (m)"
              value={data.rightSide}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div>
            <Label htmlFor="leftSide" className="text-gray-600">
              Lado Esquerdo (m)
            </Label>
            <Input
              type="text"
              id="leftSide"
              name="leftSide"
              placeholder="Lado Esquerdo (m)"
              value={data.leftSide}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
        </div>

        <h2 className="mb-4 mt-6 text-xl font-semibold text-gray-700">
          Características
        </h2>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          <div className="flex items-center space-x-2">
            <Checkbox
              id="corner"
              name="corner"
              checked={data.corner}
              onCheckedChange={(checked) => onCheckboxChange('corner', checked)}
            />
            <Label htmlFor="corner" className="text-gray-600">
              Esquina
            </Label>
          </div>

          <div className="flex items-center space-x-2">
            <Checkbox
              id="leveled"
              name="leveled"
              checked={data.leveled}
              onCheckedChange={(checked) =>
                onCheckboxChange('leveled', checked)
              }
            />
            <Label htmlFor="leveled" className="text-gray-600">
              Aterrado
            </Label>
          </div>

          <div className="flex items-center space-x-2">
            <Checkbox
              id="fenced"
              name="fenced"
              checked={data.fenced}
              onCheckedChange={(checked) => onCheckboxChange('fenced', checked)}
            />
            <Label htmlFor="fenced" className="text-gray-600">
              Murado
            </Label>
          </div>

          <div className="flex items-center space-x-2">
            <Checkbox
              id="waterSupply"
              name="waterSupply"
              checked={data.waterSupply}
              onCheckedChange={(checked) =>
                onCheckboxChange('waterSupply', checked)
              }
            />
            <Label htmlFor="waterSupply" className="text-gray-600">
              Rede de Água
            </Label>
          </div>

          <div className="flex items-center space-x-2">
            <Checkbox
              id="electricitySupply"
              name="electricitySupply"
              checked={data.electricitySupply}
              onCheckedChange={(checked) =>
                onCheckboxChange('electricitySupply', checked)
              }
            />
            <Label htmlFor="electricitySupply" className="text-gray-600">
              Rede Elétrica
            </Label>
          </div>
        </div>
      </div>
    </div>
  )
}
