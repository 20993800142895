// src/App.tsx

import './global.css'
import './fontAwesome'
import './lib/icons'

import { Helmet, HelmetProvider } from 'react-helmet-async'
import { RouterProvider } from 'react-router-dom'
import { Toaster } from 'sonner'

import { router } from './routes'

export function App() {
  return (
    <HelmetProvider>
      <Helmet titleTemplate="Sistema Iguatemar" />
      <Toaster richColors />
      <RouterProvider router={router} />
    </HelmetProvider>
  )
}
