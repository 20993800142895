import { useEffect, useRef, useState } from 'react'

interface Option {
  value: string
  label: string
}

interface MultiSelectProps {
  label?: string
  options: Option[]
  value: string[]
  onChange: (selected: string[]) => void
  placeholder?: string
}

export function MultiSelect({
  label,
  options,
  value,
  onChange,
  placeholder = 'Selecione...',
}: MultiSelectProps) {
  const [isOpen, setIsOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  // Fecha o dropdown ao clicar fora
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const toggleOption = (optionValue: string) => {
    if (value.includes(optionValue)) {
      onChange(value.filter((v) => v !== optionValue))
    } else {
      onChange([...value, optionValue])
    }
  }

  const clearSelection = () => onChange([])

  return (
    <div className="relative" ref={containerRef}>
      {label && (
        <label className="mb-1 block text-sm text-gray-700">{label}</label>
      )}
      <div
        className="flex w-full cursor-pointer flex-wrap gap-2 rounded-md border border-gray-300 bg-white p-2 shadow-sm"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {value.length === 0 ? (
          <span className="text-gray-400">{placeholder}</span>
        ) : (
          value.map((selectedValue) => {
            const option = options.find((o) => o.value === selectedValue)
            return (
              <span
                key={selectedValue}
                className="flex items-center gap-1 rounded bg-blue-100 px-2 py-1 text-blue-800"
              >
                {option?.label || selectedValue}
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation()
                    toggleOption(selectedValue)
                  }}
                  className="text-blue-500 hover:text-blue-700"
                >
                  &times;
                </button>
              </span>
            )
          })
        )}
        {value.length > 0 && (
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation()
              clearSelection()
            }}
            className="ml-auto text-red-500 hover:text-red-700"
          >
            Limpar
          </button>
        )}
      </div>
      {isOpen && (
        <div className="absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-md border border-gray-300 bg-white shadow-lg">
          {options.map((option) => (
            <div
              key={option.value}
              onClick={() => toggleOption(option.value)}
              className={`cursor-pointer px-4 py-2 hover:bg-blue-100 ${
                value.includes(option.value) ? 'bg-blue-200' : ''
              }`}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
