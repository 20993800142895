import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

import { saveAs } from 'file-saver'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx'

import { Footer } from '@/components/Footer'
import { Region } from '@/components/form-property/address'
import type {
  Attribute,
  BlockAttributes,
  CondominiumAttributes,
  LeisureData,
  PhotoItem,
  PropertyFormData,
} from '@/components/form-property/property-types'
import { Header } from '@/components/Header'
import { MultiSelect } from '@/components/multiSelect'
import { PropertyCard } from '@/components/PropertyCard'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

interface Property {
  id: number
  attributes: PropertyFormData
}

export function Home() {
  const [propertyType, setPropertyType] = useState<string[]>([])
  const [regions, setRegions] = useState<Region[]>([])
  const [region, setRegion] = useState<string[]>([])
  const [status, setStatus] = useState<string[]>([])
  const [type, setType] = useState<string[]>([])
  const [searchQuery, setSearchQuery] = useState<string>('')

  const [priceRange, setPriceRange] = useState('')

  const [appliedFilters, setAppliedFilters] = useState({
    propertyType: [] as string[],
    region: [] as string[],
    priceRange: '',
    status: [] as string[],
    type: [] as string[],
    searchQuery: '',
  })

  const [properties, setProperties] = useState<Property[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const userCollaborator = localStorage.getItem('userCollaborator')
  const isAdmin = userCollaborator === 'ADMIN'
  const itemsPerPage = 10

  const route = import.meta.env.VITE_URL_ENDPOINT

  const buildFilters = useCallback(() => {
    const params = new URLSearchParams()

    if (appliedFilters.propertyType.length) {
      appliedFilters.propertyType.forEach((pt) => {
        params.append('filters[propertyType][$in][]', pt)
      })
    }

    if (appliedFilters.status.length) {
      appliedFilters.status.forEach((s) => {
        params.append('filters[status][$in][]', s)
      })
    }

    if (appliedFilters.priceRange) {
      const [minPrice, maxPrice] = appliedFilters.priceRange.split('~')
      if (minPrice) {
        // Converte para número para evitar comparação como string
        params.append('filters[price][$gte]', String(Number(minPrice)))
      }
      if (maxPrice) {
        params.append('filters[price][$lte]', String(Number(maxPrice)))
      }
    }

    if (appliedFilters.region.length) {
      appliedFilters.region.forEach((r) => {
        params.append('filters[region][$in][]', r)
      })
    }

    if (appliedFilters.type.length) {
      appliedFilters.type.forEach((t) => {
        params.append('filters[type][$in][]', t)
      })
    }

    if (appliedFilters.searchQuery) {
      // Usamos índices altos para evitar conflito com outros filtros
      params.append(
        'filters[$or][0][street][$containsi]',
        appliedFilters.searchQuery,
      )
      params.append(
        'filters[$or][1][neighborhood][$containsi]',
        appliedFilters.searchQuery,
      )
      params.append(
        'filters[$or][2][region][$containsi]',
        appliedFilters.searchQuery,
      )
    }

    return params.toString()
  }, [appliedFilters])

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await fetch(`${route}/api/regions`)
        const result = await response.json()
        setRegions(result.data)
      } catch (error) {
        console.error('Erro ao buscar regiões:', error)
      }
    }
    fetchRegions()
  }, [route])

  // A busca é realizada somente quando os filtros aplicados (appliedFilters) ou a página atual mudam
  useEffect(() => {
    const fetchProperties = async () => {
      try {
        setLoading(true)
        const filters = buildFilters()
        const requestUrl = `${route}/api/properties?pagination[page]=${currentPage}&pagination[pageSize]=${itemsPerPage}&populate=condominium,block,photos&${filters}`
        const response = await fetch(requestUrl)
        const data = await response.json()
        let fetchedProps = data.data || []

        if (!isAdmin) {
          fetchedProps = fetchedProps.filter(
            (p: { attributes: { status: string } }) =>
              p.attributes.status !== 'Disponível',
          )
        }

        setProperties(fetchedProps)
        setTotalPages(data.meta.pagination.pageCount || 1)
      } catch (error) {
        console.error('Erro ao buscar propriedades:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchProperties()
  }, [currentPage, appliedFilters, route, buildFilters, isAdmin])

  function formatCurrency(value: string | number | null | undefined): string {
    if (!value) return 'R$ 0,00'
    const numberValue =
      typeof value === 'string' ? parseFloat(value.replace(/\D/g, '')) : value
    if (isNaN(numberValue)) return 'N/A'
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(numberValue)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const booleanToSimNao = (val: boolean | null | undefined) => {
    if (val === null || val === undefined) return ''
    return val ? 'Sim' : 'Não'
  }

  const handleSearch = () => {
    setAppliedFilters({
      propertyType,
      region,
      priceRange,
      status,
      type,
      searchQuery,
    })
    setCurrentPage(1)
  }

  // Limpa todos os filtros
  const clearFilters = () => {
    setPropertyType([])
    setRegion([])
    setPriceRange('')
    setStatus([])
    setType([])
    setSearchQuery('')
    setAppliedFilters({
      propertyType: [],
      region: [],
      priceRange: '',
      status: [],
      type: [],
      searchQuery: '',
    })
    setCurrentPage(1)
  }

  // Funções auxiliares para extrair atributos
  function getCondoAttributes(prop: PropertyFormData): CondominiumAttributes {
    const c = prop.condominium as unknown as {
      data?: { attributes?: CondominiumAttributes }
    }
    return c?.data?.attributes || ({} as CondominiumAttributes)
  }

  function getBlockAttributes(prop: PropertyFormData): BlockAttributes {
    const b = prop.block as unknown as {
      data?: { attributes?: BlockAttributes }
    }
    return b?.data?.attributes || ({} as BlockAttributes)
  }

  function getLeisureAttributes(prop: PropertyFormData): LeisureData {
    const l = prop.leisure as unknown as { data?: { attributes?: LeisureData } }
    if (l?.data?.attributes) {
      return l.data.attributes
    }
    return {
      id: 0,
      partyHall: false,
      adultPool: false,
      gameRoom: false,
      kidsPool: false,
      gym: false,
      playground: false,
      sportsCourt: false,
      sauna: false,
      tennisCourt: false,
      bbqArea: false,
      squashCourt: false,
      socialHall: false,
      wetDeckPool: false,
      lapPool: false,
      kidsBeachPool: false,
      solarium: false,
      poolBar: false,
      massageRoom: false,
      womenSpace: false,
      petPlace: false,
      youthGameRoom: false,
      toyLibrary: false,
      pilatesSpace: false,
      lounge: false,
      gourmetSpace: false,
      kidsPartyRoom: false,
      adultPartyRoomWithBar: false,
      adultGameRoom: false,
      readingRoom: false,
      hammockArea: false,
      zenSpace: false,
      coworkingSpace: false,
      adultTVRoom: false,
      gourmetBar: false,
      cinemaRoom: false,
      property: { data: null },
    }
  }

  const handleExport = async () => {
    try {
      setLoading(true)
      const filters = buildFilters()
      const requestUrl = `${route}/api/properties?populate=condominium,block,leisure,photos&${filters}`
      const response = await fetch(requestUrl)
      const data = await response.json()
      const props: Property[] = data.data || []
      const rows: Record<string, string>[] = []

      for (const p of props) {
        const prop = p.attributes
        const condo = getCondoAttributes(prop)
        const block = getBlockAttributes(prop)
        const leisure = getLeisureAttributes(prop)
        const row = {
          ID: String(p.id),
          TipoPropriedade: prop.propertyType || '',
          Tipo: prop.type || '',
          Status: prop.status || '',
          Rua: prop.street || '',
          Número: prop.number || '',
          Bairro: prop.neighborhood || '',
          Região: prop.region || '',
          CEP: prop.zipCode || '',
          ÁreaTotal: prop.totalArea || '',
          ÁreaÚtil: prop.usableArea || '',
          ÁreaComum: prop.commonArea || '',
          ÁreaPrivativa: prop.privateArea || '',
          DistanciaPraia: prop.distanceFromBeach || '',
          Preço: prop.price || '',
          Condomínio: prop.condominiumFee || '',
          IPTU: prop.iptu || '',
          AceitaPermuta: booleanToSimNao(prop.barter),
          Financiamento: booleanToSimNao(prop.financing),
          MétodoPagamento: prop.paymentMethod || '',
          VistaMar: booleanToSimNao(prop.seaView),
          ObservacaoInterna: prop.internalObservation || '',
          ObservacaoExterna: prop.externalObservation || '',
          CondNome: condo.Name || '',
          CondRegião: condo.region || '',
          CondRua: condo.street || '',
          CondBairro: condo.neighborhood || '',
          CondNúmero: condo.number || '',
          CondCEP: condo.zipCode || '',
          CondStatusComercial: condo.commercialStatus || '',
          CondConstrutora: condo.builderName || '',
          CondIncorporadora: condo.developerName || '',
          CondSíndico: condo.managerName || '',
          CondTelSíndico: condo.managerPhone || '',
          CondZelador: condo.caretakerName || '',
          CondTelZelador: condo.caretakerPhone || '',
          CondAnoConstrucao: condo.constructionYear || '',
          CondNroElevServ: condo.numberOfServiceElevators || '',
          CondNroElevSocial: condo.numberOfPassengerElevators || '',
          CondPrazoEntrega: condo.deliverySchedule || '',
          CondEsquina: booleanToSimNao(condo.corner),
          CondGuarita: booleanToSimNao(condo.guardhouse),
          CondInterfone: booleanToSimNao(condo.intercom),
          CondTerraco: booleanToSimNao(condo.terrace),
          CondGaragemSubsolo: booleanToSimNao(condo.undergroundGarage),
          CondServicoPraia: booleanToSimNao(condo.beachService),
          CondGuardaEquipPraia: booleanToSimNao(condo.beachEquipmentStorage),
          CondPortaoEletronico: booleanToSimNao(condo.electronicGate),
          CondGaragemTerrea: booleanToSimNao(condo.groundFloorGarage),
          CondGasColetivo: booleanToSimNao(condo.collectiveGas),
          BlocoNome: block.name || '',
          BlocoNroAndares: block.numberOfFloors || '',
          BlocoAptosPorAndar: block.apartmentsPerFloor || '',
          BlocoTérreoOuAndar: block.groundOrFloor || '',
          BlocoAndarNumero: block.FloorNumber || '',
          BlocoFaseConstrucao: block.ConstructionPhase || '',
          LazerSalaoFestas: booleanToSimNao(leisure.partyHall),
          LazerPiscinaAdulto: booleanToSimNao(leisure.adultPool),
          LazerSalaJogos: booleanToSimNao(leisure.gameRoom),
          LazerPiscinaInfantil: booleanToSimNao(leisure.kidsPool),
          LazerAcademia: booleanToSimNao(leisure.gym),
          LazerPlayground: booleanToSimNao(leisure.playground),
          LazerQuadraEsportiva: booleanToSimNao(leisure.sportsCourt),
          LazerSauna: booleanToSimNao(leisure.sauna),
          LazerQuadraTenis: booleanToSimNao(leisure.tennisCourt),
          LazerChurrasqueira: booleanToSimNao(leisure.bbqArea),
          LazerQuadraSquash: booleanToSimNao(leisure.squashCourt),
          LazerSalaoSocial: booleanToSimNao(leisure.socialHall),
          LazerPiscinaDeckMolhado: booleanToSimNao(leisure.wetDeckPool),
          LazerPiscinaRaia: booleanToSimNao(leisure.lapPool),
          LazerPiscinaInfPraia: booleanToSimNao(leisure.kidsBeachPool),
          LazerSolarium: booleanToSimNao(leisure.solarium),
          LazerBarPiscina: booleanToSimNao(leisure.poolBar),
          LazerSalaMassagem: booleanToSimNao(leisure.massageRoom),
          LazerEspacoMulher: booleanToSimNao(leisure.womenSpace),
          LazerPetPlace: booleanToSimNao(leisure.petPlace),
          LazerSalaJogosJuvenil: booleanToSimNao(leisure.youthGameRoom),
          LazerBrinquedoteca: booleanToSimNao(leisure.toyLibrary),
          LazerEspacoPilates: booleanToSimNao(leisure.pilatesSpace),
          LazerLounge: booleanToSimNao(leisure.lounge),
          LazerEspacoGourmet: booleanToSimNao(leisure.gourmetSpace),
          LazerSalaoFestasInfantil: booleanToSimNao(leisure.kidsPartyRoom),
          LazerSalaoFestasAdultoBar: booleanToSimNao(
            leisure.adultPartyRoomWithBar,
          ),
          LazerSalaJogosAdulto: booleanToSimNao(leisure.adultGameRoom),
          LazerSalaLeitura: booleanToSimNao(leisure.readingRoom),
          LazerHammockArea: booleanToSimNao(leisure.hammockArea),
          LazerZenSpace: booleanToSimNao(leisure.zenSpace),
          LazerCoworking: booleanToSimNao(leisure.coworkingSpace),
          LazerSalaTVAdulto: booleanToSimNao(leisure.adultTVRoom),
          LazerGourmetBar: booleanToSimNao(leisure.gourmetBar),
          LazerCinema: booleanToSimNao(leisure.cinemaRoom),
        }
        rows.push(row)
      }

      const worksheet = XLSX.utils.json_to_sheet(rows)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Propriedades')
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
      const blob = new Blob([wbout], { type: 'application/octet-stream' })
      saveAs(blob, 'imoveis.xlsx')
    } catch (error) {
      console.error('Erro ao exportar propriedades:', error)
    } finally {
      setLoading(false)
    }
  }

  const navigate = useNavigate()
  useEffect(() => {
    const user = localStorage.getItem('userCollaborator')
    if (!user) {
      navigate('/')
    }
  }, [navigate])

  // Arrays de opções para cada filtro
  const regionOptions = regions.map((r) => ({
    value: r.attributes.Name,
    label: r.attributes.Name,
  }))

  const statusOptions = [
    { value: 'À venda', label: 'À venda' },
    { value: 'Vendido', label: 'Vendido' },
    { value: 'Suspenso', label: 'Suspenso' },
    { value: 'Locação', label: 'Locação' },
    ...(isAdmin ? [{ value: 'Disponível', label: 'Disponível' }] : []),
    { value: 'Lançamento', label: 'Lançamento' },
  ]

  const propertyTypeOptions = [
    { value: 'apartment', label: 'Apartamento' },
    { value: 'house', label: 'Casa' },
    { value: 'commercial_property', label: 'Área Comercial' },
    { value: 'area', label: 'Área' },
    { value: 'terrain', label: 'Terreno' },
  ]

  // Para o filtro "Subtipo", combinamos as opções dos tipos selecionados
  let subtypeOptions: { value: string; label: string }[] = []
  if (propertyType.length) {
    propertyType.forEach((pt) => {
      switch (pt) {
        case 'apartment':
          subtypeOptions = [
            ...subtypeOptions,
            { value: 'Apartamento', label: 'Apartamento' },
            { value: 'Flat', label: 'Flat' },
            { value: 'Cobertura', label: 'Cobertura' },
            { value: 'Gardem', label: 'Gardem' },
            { value: 'Triplex', label: 'Triplex' },
            { value: 'Kitnet', label: 'Kitnet' },
            { value: 'Penthouse', label: 'Penthouse' },
            { value: 'Studio', label: 'Studio' },
          ]
          break
        case 'house':
          subtypeOptions = [
            ...subtypeOptions,
            { value: 'Casa', label: 'Casa' },
            { value: 'Sobrado', label: 'Sobrado' },
            { value: 'Duplex', label: 'Duplex' },
          ]
          break
        case 'commercial_property':
          subtypeOptions = [
            ...subtypeOptions,
            { value: 'Pousada', label: 'Pousada' },
            { value: 'Conjunto', label: 'Conjunto' },
            { value: 'Loja', label: 'Loja' },
            { value: 'Prédio', label: 'Prédio' },
            { value: 'Sala', label: 'Sala' },
            { value: 'Galpão', label: 'Galpão' },
            { value: 'Hotel', label: 'Hotel' },
          ]
          break
        case 'area':
          subtypeOptions = [
            ...subtypeOptions,
            { value: 'Residencial', label: 'Residencial' },
            { value: 'Comercial', label: 'Comercial' },
            { value: 'Industrial', label: 'Industrial' },
          ]
          break
        default:
          break
      }
    })
    // Remover duplicatas, se houver
    subtypeOptions = subtypeOptions.filter(
      (option, index, self) =>
        index === self.findIndex((o) => o.value === option.value),
    )
  }

  useEffect(() => {
    const user = localStorage.getItem('userCollaborator')
    if (!user) {
      navigate('/')
    }
  }, [navigate])

  return (
    <>
      <Header
        filterContent={
          <div>
            {/* Área de Filtros */}
            <div className="flex flex-wrap items-end justify-center gap-3 sm:gap-4">
              <div className="w-[250px]">
                <MultiSelect
                  label="Região"
                  placeholder="Selecione a Região"
                  options={regionOptions}
                  value={region}
                  onChange={setRegion}
                />
              </div>
              <div className="w-[250px]">
                <MultiSelect
                  label="Status"
                  placeholder="Selecione o Status"
                  options={statusOptions}
                  value={status}
                  onChange={setStatus}
                />
              </div>
              <div className="w-[250px]">
                <MultiSelect
                  label="Tipo"
                  placeholder="Ex.: Apartamento, Casa, etc."
                  options={propertyTypeOptions}
                  value={propertyType}
                  onChange={setPropertyType}
                />
              </div>
              <div className="w-[250px]">
                <MultiSelect
                  label="Subtipo"
                  placeholder="Selecione o Subtipo"
                  options={subtypeOptions}
                  value={type}
                  onChange={setType}
                />
              </div>
              <div className="flex flex-col">
                <Label className="text-sm text-black">Preço</Label>
                <Select
                  value={priceRange}
                  onValueChange={(value) => setPriceRange(value)}
                >
                  <SelectTrigger
                    id="price"
                    name="price"
                    className="mt-1 w-[160px] text-sm"
                  >
                    <SelectValue placeholder="Faixa de preço" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="~400000">Até 400.000 </SelectItem>
                    <SelectItem value="400000~600000">
                      400.000 até 600.000
                    </SelectItem>
                    <SelectItem value="600000~800000">
                      600.000 até 800.000
                    </SelectItem>
                    <SelectItem value="800000~1000000">
                      800.000 até 1.000.000
                    </SelectItem>
                    <SelectItem value="1000000~2000000">
                      1.000.000 até 2.000.000
                    </SelectItem>
                    <SelectItem value="2000000~4000000">
                      2.000.000 até 4.000.000
                    </SelectItem>
                    <SelectItem value="4000000~6000000">
                      4.000.000 até 6.000.000
                    </SelectItem>
                    <SelectItem value="6000000~8000000">
                      6.000.000 até 8.000.000
                    </SelectItem>
                    <SelectItem value="8000000~10000000">
                      8.000.000 até 10.000.000
                    </SelectItem>
                    <SelectItem value="10000000~20000000">
                      10.000.000 até 20.000.000
                    </SelectItem>
                    <SelectItem value="20000000~40000000">
                      20.000.000 até 40.000.000
                    </SelectItem>
                    <SelectItem value="40000000~60000000">
                      40.000.000 até 60.000.000
                    </SelectItem>
                    <SelectItem value="60000000~">
                      Acima de 60.000.000
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>

            {/* Busca e Botões */}
            <div className="mt-6 flex flex-wrap items-center justify-center gap-4 sm:gap-6">
              <input
                type="text"
                placeholder="Buscar imóveis"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-[250px] rounded-md border px-4 py-2 text-sm sm:w-[320px]"
              />

              <button
                type="button"
                onClick={handleSearch}
                className="flex items-center justify-center gap-2 rounded-md bg-blue-500 px-4 py-2 text-sm text-white hover:bg-blue-600"
              >
                <i className="fa fa-search" />
                Pesquisar
              </button>

              <button
                type="button"
                onClick={clearFilters}
                className="flex items-center justify-center gap-2 rounded-md bg-gray-500 px-4 py-2 text-sm text-white hover:bg-gray-600"
              >
                <i className="fa fa-times" />
                Limpar Filtros
              </button>
              <button
                type="button"
                onClick={handleExport}
                className="flex items-center justify-center gap-2 rounded-md bg-gray-500 px-4 py-2 text-sm text-white hover:bg-gray-600"
              >
                <i className="fa fa-download" />
                Exportar dados
              </button>
            </div>
          </div>
        }
      />

      <div className="container mx-auto mt-10 px-4">
        {loading && (
          <div className="mt-10 text-center text-gray-500">
            Carregando imóveis...
          </div>
        )}
        {!loading && properties && properties.length > 0
          ? properties.map((property) => {
              if (!property || !property.attributes) return null
              const attributes = property.attributes
              const images = (
                (attributes.photos as { data: PhotoItem[] } | undefined)
                  ?.data ?? []
              ).map(
                (photoItem: PhotoItem) => `${route}${photoItem.attributes.url}`,
              )

              let description = ''
              let propertyAttributes: Attribute[] = []

              if (attributes.propertyType === 'apartment') {
                description = 'Apartamento'
                propertyAttributes = [
                  {
                    label: 'Quartos',
                    value: attributes.numberOfBedrooms || 'N/A',
                  },
                  {
                    label: 'Suítes',
                    value: attributes.numberOfSuites || 'N/A',
                  },
                  { label: 'Vagas', value: attributes.garage || 'N/A' },
                  {
                    label: 'Área (m²)',
                    value: attributes.usableArea || 'N/A',
                  },
                ]
              } else if (attributes.propertyType === 'house') {
                description = 'Casa'
                propertyAttributes = [
                  {
                    label: 'Quartos',
                    value: attributes.numberOfBedrooms || 'N/A',
                  },
                  {
                    label: 'Suítes',
                    value: attributes.numberOfSuites || 'N/A',
                  },
                  { label: 'Vagas', value: attributes.garage || 'N/A' },
                  {
                    label: 'Área (m²)',
                    value: attributes.totalArea || 'N/A',
                  },
                ]
              } else if (attributes.propertyType === 'area') {
                description = 'Área'
                propertyAttributes = [
                  {
                    label: 'Área Total (m²)',
                    value: attributes.totalArea || 'N/A',
                  },
                ]
              } else if (attributes.propertyType === 'commercial_property') {
                description = 'Comercial'
                propertyAttributes = [
                  {
                    label: 'Banheiros',
                    value: attributes.bathrooms || 'N/A',
                  },
                  {
                    label: 'Guarita',
                    value: attributes.guardhouse || 'N/A',
                  },
                  {
                    label: 'Salas',
                    value: attributes.numberOfRooms || 'N/A',
                  },
                  {
                    label: 'Área (m²)',
                    value: attributes.totalArea || 'N/A',
                  },
                ]
              } else if (attributes.propertyType === 'terrain') {
                description = 'Terreno'
                propertyAttributes = [
                  {
                    label: 'Área Total (m²)',
                    value: attributes.totalArea || 'N/A',
                  },
                ]
              } else {
                return null
              }

              const TitleWithRemoveApartament =
                attributes.type === 'Apartamento' ||
                attributes.propertyType === 'terrain'
                  ? ''
                  : `- ${attributes.type}`

              const title = `${attributes.neighborhood || ''} - ${description || ''} ${TitleWithRemoveApartament || ''}`
              const additionalInfo = `Região: ${attributes.region || ''}`
              const apNumber = attributes.apartmentNumber
                ? ` - Ap. ${attributes.apartmentNumber}`
                : ''

              // Monta a descrição da localização
              let locationDescription = `${attributes.street || ''}, ${attributes.number || ''} ${apNumber}`
              if (getCondoAttributes(attributes).Name) {
                locationDescription += ` - ${getCondoAttributes(attributes).Name}`
              }
              if (getBlockAttributes(attributes).name) {
                locationDescription += ` - ${getBlockAttributes(attributes).name}`
              }

              if (
                attributes.propertyType === 'terrain' ||
                attributes.propertyType === 'area'
              ) {
                const quadra = attributes.quadra
                  ? `${attributes.street || ''}, ${attributes.number || ''} - Quadra ${attributes.quadra}`
                  : ''
                const lote = attributes.lote ? `Lote(s) ${attributes.lote}` : ''
                locationDescription = [quadra, lote].filter(Boolean).join(' - ')
              }

              return (
                <PropertyCard
                  key={property.id}
                  id={property.id}
                  title={title}
                  description={locationDescription}
                  additionalInfo={additionalInfo}
                  status={attributes.status}
                  images={images}
                  price={formatCurrency(attributes.price)}
                  condominiumPrice={
                    attributes.condominiumFee
                      ? formatCurrency(attributes.condominiumFee)
                      : 'N/A'
                  }
                  iptuPrice={
                    attributes.iptu ? formatCurrency(attributes.iptu) : 'N/A'
                  }
                  attributes={propertyAttributes}
                />
              )
            })
          : !loading && (
              <div className="mt-10 text-center text-gray-500">
                Nenhum imóvel encontrado.
              </div>
            )}

        {!loading && properties.length > 0 && (
          <div className="pagination mt-8 flex justify-center">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="mx-2 rounded-md bg-gray-300 px-4 py-2 text-sm disabled:opacity-50"
            >
              Anterior
            </button>
            <span className="mx-2 py-2 text-sm">
              Página {currentPage} de {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="mx-2 rounded-md bg-gray-300 px-4 py-2 text-sm disabled:opacity-50"
            >
              Próxima
            </button>
          </div>
        )}
      </div>
      <Footer />
    </>
  )
}
