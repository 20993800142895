// PropertyOptions.tsx
import type { PropertyFormData } from '@/components/form-property/property-types'
import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'

interface PropertyOptionsProps {
  formData: PropertyFormData
  setFormData: React.Dispatch<React.SetStateAction<PropertyFormData>>
}

export function PropertyOptions({
  formData,
  setFormData,
}: PropertyOptionsProps) {
  const handleCheckboxChange = (
    name: keyof PropertyFormData,
    checked: boolean | 'indeterminate',
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: checked === true,
    }))
  }

  return (
    <>
      <h2 className="mb-4 mt-6 text-xl font-semibold text-gray-700">Opções</h2>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        <div className="flex items-center space-x-2">
          <Checkbox
            id="seaView"
            name="seaView"
            checked={formData.seaView}
            onCheckedChange={(checked) =>
              handleCheckboxChange('seaView', checked)
            }
          />
          <Label htmlFor="seaView" className="text-gray-600">
            Vista para o Mar
          </Label>
        </div>
      </div>
    </>
  )
}
