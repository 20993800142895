import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'

import { PropertyFormData } from '@/components/form-property/property-types'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

// Tipo de região vinda do backend
export interface Region {
  id: number
  attributes: {
    Name: string
    Observation?: string
  }
}

interface AddressProps {
  data: PropertyFormData
  onChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => void
}

export function Address({ data, onChange }: AddressProps) {
  const [regions, setRegions] = useState<Region[]>([])
  const route = import.meta.env.VITE_URL_ENDPOINT

  // Busca as regiões do Strapi
  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await fetch(`${route}/api/regions`)
        const result = await response.json()
        setRegions(result.data)
      } catch (error) {
        console.error('Erro ao buscar regiões:', error)
      }
    }
    fetchRegions()
  }, [route])

  // Ao mudar o CEP, simplesmente repassa para o "onChange"
  const handleCepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e)
  }

  // Busca endereço via ViaCEP ao digitar CEP
  useEffect(() => {
    const fetchAddressFromCep = async (cep: string) => {
      const sanitizedCep = cep.replace(/\D/g, '')
      if (sanitizedCep.length === 8) {
        try {
          const response = await fetch(
            `https://viacep.com.br/ws/${sanitizedCep}/json/`,
          )
          const addressData = await response.json()

          if (!addressData.erro) {
            onChange({
              target: {
                name: 'street',
                value: addressData.logradouro || '',
              },
            } as React.ChangeEvent<HTMLInputElement>)

            onChange({
              target: {
                name: 'neighborhood',
                value: addressData.bairro || '',
              },
            } as React.ChangeEvent<HTMLInputElement>)

            // Caso queira também preencher cidade:
            // onChange({ target: { name: 'city', value: addressData.localidade } } as ...)
          } else {
            console.error('CEP não encontrado.')
          }
        } catch (error) {
          console.error('Erro ao buscar endereço:', error)
        }
      }
    }

    if (data.zipCode) {
      fetchAddressFromCep(data.zipCode)
    }
  }, [data.zipCode, onChange])

  return (
    <div className="w-full bg-gray-50 p-6 sm:p-8">
      <h1 className="mb-6 text-center text-3xl font-bold text-gray-800">
        Detalhes do Imóvel
      </h1>

      {/* Status */}
      <div className="flex flex-row space-x-5">
        <div className="mb-5 flex w-64 flex-col">
          <Label htmlFor="status" className="text-gray-600">
            Status
          </Label>
          <Select
            value={data.status || ''}
            onValueChange={(value: string) =>
              onChange({
                target: { name: 'status', value },
              } as React.ChangeEvent<HTMLInputElement>)
            }
          >
            <SelectTrigger id="status" name="status" className="mt-1">
              <SelectValue placeholder="Selecione o status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="À venda">À venda</SelectItem>
              <SelectItem value="Vendido">Vendido</SelectItem>
              <SelectItem value="Suspenso">Suspenso</SelectItem>
              <SelectItem value="Locação">Locação</SelectItem>
              <SelectItem value="Disponível">Disponível</SelectItem>
              <SelectItem value="Lançamento">Lançamento</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="mb-5 flex w-64 flex-col">
          <Label htmlFor="lado" className="text-gray-600">
            Lado
          </Label>
          <Select
            value={data.lado || ''}
            onValueChange={(value: string) =>
              onChange({
                target: { name: 'lado', value },
              } as React.ChangeEvent<HTMLInputElement>)
            }
          >
            <SelectTrigger id="lado" name="lado" className="mt-1">
              <SelectValue placeholder="Selecione o lado" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="P">P</SelectItem>
              <SelectItem value="M">M</SelectItem>
              <SelectItem value="O">O</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      {/* SEÇÃO: Localização */}
      <h2 className="mb-4 text-xl font-semibold text-gray-700">Localização</h2>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        {/* CEP */}
        <div className="flex flex-col">
          <Label htmlFor="zipCode" className="text-gray-600">
            CEP
          </Label>
          <Input
            type="text"
            id="zipCode"
            name="zipCode"
            placeholder="Ex: 12345678"
            value={data.zipCode || ''}
            onChange={handleCepChange}
            maxLength={8}
            className="mt-1"
          />
        </div>

        <div className="flex flex-col">
          <Label htmlFor="street" className="text-gray-600">
            Rua
          </Label>
          <Input
            id="street"
            name="street"
            placeholder="Ex: Av. Brasil"
            value={data.street || ''}
            onChange={onChange}
            className="mt-1"
          />
        </div>

        <div className="flex flex-col">
          <Label htmlFor="number" className="text-gray-600">
            Número
          </Label>
          <Input
            id="number"
            name="number"
            placeholder="Ex: 123"
            value={data.number || ''}
            onChange={onChange}
            className="mt-1 w-32"
          />
        </div>

        {/* Região */}
        <div className="flex flex-col">
          <Label htmlFor="region" className="text-gray-600">
            Região
          </Label>
          <Select
            value={data.region || ''}
            onValueChange={(value: string) =>
              onChange({
                target: { name: 'region', value },
              } as React.ChangeEvent<HTMLInputElement>)
            }
          >
            <SelectTrigger id="region" name="region" className="mt-1">
              <SelectValue placeholder="Selecione a região" />
            </SelectTrigger>
            <SelectContent>
              {regions.map((reg) => (
                <SelectItem key={reg.id} value={reg.attributes.Name}>
                  {reg.attributes.Name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex flex-col">
          <Label htmlFor="neighborhood" className="text-gray-600">
            Bairro
          </Label>
          <Input
            id="neighborhood"
            name="neighborhood"
            placeholder="Ex: Centro"
            value={data.neighborhood || ''}
            onChange={onChange}
            className="mt-1"
          />
        </div>

        <div className="flex flex-col">
          <Label htmlFor="city" className="text-gray-600">
            Cidade
          </Label>
          <Input
            id="city"
            name="city"
            placeholder="Ex: Santos"
            value={data.city || ''}
            onChange={onChange}
            className="mt-1"
          />
        </div>
      </div>

      {/* SEÇÃO: Áreas */}
      <h2 className="mb-4 mt-8 text-xl font-semibold text-gray-700">Áreas</h2>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
        <div className="flex flex-col">
          <Label htmlFor="totalArea" className="text-gray-600">
            Área Total (m²)
          </Label>
          <Input
            id="totalArea"
            name="totalArea"
            placeholder="Ex: 200"
            value={data.totalArea || ''}
            onChange={onChange}
            className="mt-1"
          />
        </div>

        <div className="flex flex-col">
          <Label htmlFor="usableArea" className="text-gray-600">
            Área Útil (m²)
          </Label>
          <Input
            id="usableArea"
            name="usableArea"
            placeholder="Ex: 150"
            value={data.usableArea || ''}
            onChange={onChange}
            className="mt-1"
          />
        </div>

        <div className="flex flex-col">
          <Label htmlFor="commonArea" className="text-gray-600">
            Área Comum (m²)
          </Label>
          <Input
            id="commonArea"
            name="commonArea"
            placeholder="Ex: 50"
            value={data.commonArea || ''}
            onChange={onChange}
            className="mt-1"
          />
        </div>
      </div>

      {/* SEÇÃO: Valores */}
      <h2 className="mb-4 mt-8 text-xl font-semibold text-gray-700">Valores</h2>
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
        <div className="flex flex-col">
          <Label htmlFor="price" className="text-gray-600">
            Valor do Imóvel
          </Label>
          <CurrencyInput
            id="price"
            name="price"
            placeholder="R$ 0,00"
            value={data.price}
            decimalsLimit={2}
            decimalSeparator=","
            groupSeparator="."
            prefix="R$ "
            onValueChange={(value, name) => {
              onChange({
                target: {
                  name: name || 'price',
                  value: value || '',
                },
              } as React.ChangeEvent<HTMLInputElement>)
            }}
            customInput={Input}
            className="mt-1"
          />
        </div>

        <div className="flex flex-col">
          <Label htmlFor="condominiumFee" className="text-gray-600">
            Condomínio
          </Label>
          <CurrencyInput
            id="condominiumFee"
            name="condominiumFee"
            placeholder="R$ 0,00"
            value={data.condominiumFee}
            decimalsLimit={2}
            decimalSeparator=","
            groupSeparator="."
            prefix="R$ "
            onValueChange={(value, name) => {
              onChange({
                target: {
                  name: name || 'condominiumFee',
                  value: value || '',
                },
              } as React.ChangeEvent<HTMLInputElement>)
            }}
            customInput={Input}
            className="mt-1"
          />
        </div>

        <div className="flex flex-col">
          <Label htmlFor="iptu" className="text-gray-600">
            IPTU
          </Label>
          <CurrencyInput
            id="iptu"
            name="iptu"
            placeholder="R$ 0,00"
            value={data.iptu}
            decimalsLimit={2}
            decimalSeparator=","
            groupSeparator="."
            prefix="R$ "
            onValueChange={(value, name) => {
              onChange({
                target: {
                  name: name || 'iptu',
                  value: value || '',
                },
              } as React.ChangeEvent<HTMLInputElement>)
            }}
            customInput={Input}
            className="mt-1"
          />
        </div>
      </div>

      <div className="mt-4 grid grid-cols-1 gap-4 lg:grid-cols-3">
        <div className="flex flex-col">
          <Label htmlFor="workFund" className="text-gray-600">
            Fundo de Obra
          </Label>
          <CurrencyInput
            id="workFund"
            name="workFund"
            placeholder="R$ 0,00"
            value={data.workFund}
            decimalsLimit={2}
            decimalSeparator=","
            groupSeparator="."
            prefix="R$ "
            onValueChange={(value, name) => {
              onChange({
                target: {
                  name: name || 'workFee',
                  value: value || '',
                },
              } as React.ChangeEvent<HTMLInputElement>)
            }}
            customInput={Input}
            className="mt-1"
          />
        </div>

        <div className="flex flex-col">
          <Label htmlFor="paymentMethod" className="text-gray-600">
            Condições de Pagamento
          </Label>
          <Input
            id="paymentMethod"
            name="paymentMethod"
            placeholder="Ex: Financiamento, À vista..."
            value={data.paymentMethod || ''}
            onChange={onChange}
            className="mt-1"
          />
        </div>
      </div>

      {/* SEÇÃO: Observações */}
      <h2 className="mb-4 mt-8 text-xl font-semibold text-gray-700">
        Observações
      </h2>
      <div className="grid grid-cols-1 gap-4">
        <div className="flex flex-col">
          <Label htmlFor="externalObservation" className="text-gray-600">
            Observação Externa
          </Label>
          <textarea
            id="externalObservation"
            name="externalObservation"
            placeholder="Comentários visíveis externamente..."
            value={data.externalObservation || ''}
            onChange={onChange}
            rows={3}
            className="mt-1 w-full resize-none rounded-md border border-gray-300 p-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>

        <div className="flex flex-col">
          <Label htmlFor="internalObservation" className="text-gray-600">
            Observação Interna
          </Label>
          <textarea
            id="internalObservation"
            name="internalObservation"
            placeholder="Comentários internos (não visíveis ao cliente)..."
            value={data.internalObservation || ''}
            onChange={onChange}
            rows={3}
            className="mt-1 w-full resize-none rounded-md border border-gray-300 p-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>
        <div className="mt-5 flex flex-col">
          <Label htmlFor="distanceFromBeach" className="text-gray-600">
            Distância da Praia (m)
          </Label>
          <Input
            id="distanceFromBeach"
            name="distanceFromBeach"
            placeholder="Ex: 300"
            value={data.distanceFromBeach || ''}
            onChange={onChange}
            className="mt-1"
          />
        </div>
        <div className="mt-5 flex flex-col">
          <Label htmlFor="distanceFromBeach" className="text-gray-600">
            Nome(s) do Captador(s)
          </Label>
          <Input
            id="captador"
            name="captador"
            placeholder="Nome do captador"
            value={data.captador || ''}
            onChange={onChange}
            className="mt-1"
          />
        </div>
      </div>
    </div>
  )
}
