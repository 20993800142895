// PropertyTypeForm.tsx

import { Apartment } from '@/components/form-property/apartament' // Corrigido
import { Area } from '@/components/form-property/area'
import { Comercial } from '@/components/form-property/comercialProperty'
import { House } from '@/components/form-property/house'
import type {
  ApartmentData,
  AreaData,
  CommercialPropertyData,
  HouseData,
  PropertyFormData,
  TerrainData,
} from '@/components/form-property/property-types'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

import { Terrain } from './terrain'

interface PropertyTypeFormProps {
  formData: PropertyFormData
  setFormData: React.Dispatch<React.SetStateAction<PropertyFormData>>
}

export function PropertyTypeForm({
  formData,
  setFormData,
}: PropertyTypeFormProps) {
  /**
   * Handler para atualizar o tipo de propriedade selecionado.
   */
  const handlePropertyTypeChange = (value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      propertyType: value as PropertyFormData['propertyType'],
      // Inicialize os dados específicos do tipo de propriedade selecionado
      apartment: value === 'apartment' ? ({} as ApartmentData) : undefined,
      house: value === 'house' ? ({} as HouseData) : undefined,
      commercial_property:
        value === 'commercial_property'
          ? ({} as CommercialPropertyData)
          : undefined,
      area: value === 'area' ? ({} as AreaData) : undefined,
      terrain: value === 'terrain' ? ({} as TerrainData) : undefined,
    }))
  }

  /**
   * Handler para atualizar os campos do apartamento.
   */
  const handleApartmentChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value, type } = e.target
    const fieldName = name as keyof ApartmentData

    if (type === 'checkbox') {
      const target = e.target as HTMLInputElement
      setFormData((prevState) => ({
        ...prevState,
        apartment: {
          ...prevState.apartment,
          [fieldName]: target.checked,
        },
      }))
    } else {
      setFormData((prevState) => ({
        ...prevState,
        apartment: {
          ...prevState.apartment,
          [fieldName]: value,
        },
      }))
    }
  }

  /**
   * Handler para checkboxes do apartamento com possibilidade de estado indeterminado.
   */
  const handleApartmentCheckboxChange = (
    name: keyof ApartmentData,
    checked: boolean | 'indeterminate',
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      apartment: {
        ...prevState.apartment,
        [name]: checked === true,
      },
    }))
  }

  /**
   * Handler para atualizar os campos da casa.
   */
  const handleHouseChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value, type } = e.target
    const fieldName = name as keyof HouseData

    if (type === 'checkbox') {
      const target = e.target as HTMLInputElement
      setFormData((prevState) => ({
        ...prevState,
        house: {
          ...prevState.house,
          [fieldName]: target.checked,
        },
      }))
    } else {
      setFormData((prevState) => ({
        ...prevState,
        house: {
          ...prevState.house,
          [fieldName]: value,
        },
      }))
    }
  }

  /**
   * Handler para checkboxes da casa com possibilidade de estado indeterminado.
   */
  const handleHouseCheckboxChange = (
    name: keyof HouseData,
    checked: boolean | 'indeterminate',
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      house: {
        ...prevState.house,
        [name]: checked === true,
      },
    }))
  }

  /**
   * Handler para atualizar os campos da propriedade comercial.
   */
  const handleCommercialPropertyChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value, type } = e.target
    const fieldName = name as keyof CommercialPropertyData

    if (type === 'checkbox') {
      const target = e.target as HTMLInputElement
      setFormData((prevState) => ({
        ...prevState,
        commercial_property: {
          ...prevState.commercial_property,
          [fieldName]: target.checked,
        },
      }))
    } else {
      setFormData((prevState) => ({
        ...prevState,
        commercial_property: {
          ...prevState.commercial_property,
          [fieldName]: value,
        },
      }))
    }
  }

  /**
   * Handler para checkboxes da propriedade comercial com possibilidade de estado indeterminado.
   */
  const handleCommercialCheckboxChange = (
    name: keyof CommercialPropertyData,
    checked: boolean | 'indeterminate',
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      commercial_property: {
        ...prevState.commercial_property,
        [name]: checked === true,
      },
    }))
  }

  /**
   * Handler para atualizar os campos da área.
   */
  const handleAreaChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value, type } = e.target
    const fieldName = name as keyof AreaData

    if (type === 'checkbox') {
      const target = e.target as HTMLInputElement
      setFormData((prevState) => ({
        ...prevState,
        area: {
          ...prevState.area,
          [fieldName]: target.checked,
        },
      }))
    } else {
      setFormData((prevState) => ({
        ...prevState,
        area: {
          ...prevState.area,
          [fieldName]: value,
        },
      }))
    }
  }

  /**
   * Handler para checkboxes da área com possibilidade de estado indeterminado.
   */
  const handleAreaCheckboxChange = (
    name: keyof AreaData,
    checked: boolean | 'indeterminate',
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      area: {
        ...prevState.area,
        [name]: checked === true,
      },
    }))
  }

  const handleTerrainChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value, type } = e.target
    const fieldName = name as keyof TerrainData

    if (type === 'checkbox') {
      const target = e.target as HTMLInputElement
      setFormData((prevState) => ({
        ...prevState,
        terrain: {
          ...prevState.terrain,
          [fieldName]: target.checked,
        },
      }))
    } else {
      setFormData((prevState) => ({
        ...prevState,
        terrain: {
          ...prevState.terrain,
          [fieldName]: value,
        },
      }))
    }
  }

  /**
   * Handler para checkboxes da área com possibilidade de estado indeterminado.
   */
  const handleTerrainCheckboxChange = (
    name: keyof TerrainData,
    checked: boolean | 'indeterminate',
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      terrain: {
        ...prevState.terrain,
        [name]: checked === true,
      },
    }))
  }

  return (
    <>
      <div>
        <h2 className="mb-4 mt-6 text-2xl font-semibold text-gray-700">
          Tipo de Imóvel
        </h2>
        <Select
          value={formData.propertyType}
          onValueChange={handlePropertyTypeChange}
        >
          <SelectTrigger
            id="propertyType"
            name="propertyType"
            className="w-[180px]"
          >
            <SelectValue placeholder="Selecione o tipo de propriedade" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="apartment">Apartamento</SelectItem>
            <SelectItem value="house">Casa</SelectItem>
            <SelectItem value="commercial_property">
              Imóvel Comercial
            </SelectItem>
            <SelectItem value="area">Área</SelectItem>
            <SelectItem value="terrain">Terreno</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div className="mt-6">
        {formData.propertyType === 'apartment' && formData.apartment && (
          <Apartment
            data={formData.apartment}
            onChange={handleApartmentChange}
            onCheckboxChange={handleApartmentCheckboxChange}
          />
        )}
        {formData.propertyType === 'house' && formData.house && (
          <House
            data={formData.house}
            onChange={handleHouseChange}
            onCheckboxChange={handleHouseCheckboxChange}
          />
        )}
        {formData.propertyType === 'commercial_property' &&
          formData.commercial_property && (
            <Comercial
              data={formData.commercial_property}
              onChange={handleCommercialPropertyChange}
              onCheckboxChange={handleCommercialCheckboxChange}
            />
          )}
        {formData.propertyType === 'area' && formData.area && (
          <Area
            data={formData.area}
            onChange={handleAreaChange}
            onCheckboxChange={handleAreaCheckboxChange}
          />
        )}
        {formData.propertyType === 'terrain' && formData.terrain && (
          <Terrain
            data={formData.terrain}
            onChange={handleTerrainChange}
            onCheckboxChange={handleTerrainCheckboxChange}
          />
        )}
      </div>
    </>
  )
}
