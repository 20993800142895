import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef, useState } from 'react'

import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'

interface SelectedPhoto {
  id?: number
  file?: File
  url: string
}
interface PhotoUploadProps {
  selectedFiles: SelectedPhoto[]
  setSelectedFiles: React.Dispatch<React.SetStateAction<SelectedPhoto[]>>
  propertyId?: number
}

export function PhotoUpload({
  selectedFiles,
  setSelectedFiles,
}: PhotoUploadProps) {
  // Mensagem de erro caso ultrapasse o limite de 25 imagens
  const [errorPhotos, setErrorPhotos] = useState('')
  // Imagem que será exibida em um modal de preview
  const [previewImage, setPreviewImage] = useState<string | null>(null)

  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    const filesArray = Array.from(e.target.files)

    const existingCount = selectedFiles.length
    const totalCount = existingCount + filesArray.length

    if (totalCount > 25) {
      setErrorPhotos('Limite de 25 imagens excedido.')
      if (fileInputRef.current) fileInputRef.current.value = ''
      return
    }

    setErrorPhotos('')
    const newPhotos: SelectedPhoto[] = filesArray.map((file) => ({
      file,
      url: URL.createObjectURL(file),
    }))
    // Limita para 25 itens
    const limitedPhotos = [...selectedFiles, ...newPhotos].slice(0, 25)
    setSelectedFiles(limitedPhotos)

    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  /** Abre o modal de preview com a imagem selecionada */
  const openImagePreview = (src: string) => {
    setPreviewImage(src)
  }

  /** Fecha o modal de preview */
  const closeImagePreview = () => {
    setPreviewImage(null)
  }

  return (
    <div className="mb-6">
      <Label className="mb-2 block font-semibold text-gray-700">
        Fotos da propriedade (máximo 25)
      </Label>

      {/* Input para seleção de várias imagens de uma só vez */}
      <Input
        ref={fileInputRef}
        type="file"
        onChange={handleFileChange}
        accept="image/*"
        multiple
        className="mt-1 w-full"
      />

      {/* Mensagem de erro caso ultrapasse o limite de 25 */}
      {errorPhotos && (
        <div className="mt-2 text-sm text-red-500">{errorPhotos}</div>
      )}

      <div className="mt-4 grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
        {selectedFiles.map((photo, index) => (
          <div key={index} className="relative">
            <div
              className="relative h-32 cursor-pointer overflow-hidden rounded-md border"
              onClick={() => openImagePreview(photo.url)}
            >
              <img
                src={photo.url}
                alt={`Property image ${index + 1}`}
                className="h-full w-full object-cover"
              />
              <div className="absolute bottom-0 right-0 m-1 rounded-full bg-white p-1 text-gray-600 opacity-80 hover:opacity-100">
                <FontAwesomeIcon icon={faSearch} size="sm" />
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal (overlay) de preview da imagem em tela cheia */}
      {previewImage && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
          onClick={closeImagePreview}
        >
          <div className="relative max-h-[80vh] max-w-[90vw]">
            <img
              src={previewImage}
              alt="Preview"
              className="max-h-[80vh] max-w-[90vw] object-contain"
            />
            <button
              type="button"
              onClick={closeImagePreview}
              className="absolute right-2 top-2 rounded-full bg-white p-2 text-red-500 shadow hover:bg-red-50"
              title="Fechar"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
