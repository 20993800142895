import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

import {
  IconName,
  IconPrefix,
  library,
} from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Logo from '@/assets/Logo Branco.png'
import { Footer } from '@/components/Footer'
import {
  BlockAttributes,
  CondominiumAttributes,
  LeisureData,
  PhotoItem,
  PropertyFormData,
} from '@/components/form-property/property-types'

library.add(fas)

// Tipos relacionados à resposta do Strapi
interface StrapiEntity<T> {
  data: {
    id: number
    attributes: T
  } | null
}

interface OwnerAttributes {
  name: string
  telephone: string
  cell_phone: string
  email: string
  observation: string
}

type PropertyAttributes = Omit<
  PropertyFormData,
  'photos' | 'leisure' | 'condominiumData'
> & {
  photos?: { data: PhotoItem[] }
  condominium?: StrapiEntity<CondominiumAttributes>
  block?: StrapiEntity<BlockAttributes>
  leisure?: StrapiEntity<LeisureData>
  owner?: StrapiEntity<OwnerAttributes>
  updatedAt?: string
  createdAt?: string
  linkPhotos?: string
  lastEditor?: string
}

interface PropertyStrapiData {
  id: number
  attributes: PropertyAttributes
}

interface PropertyStrapiResponse {
  data: PropertyStrapiData
}

interface CondominiumResponse {
  data: {
    id: number
    attributes: CondominiumAttributes & {
      leisure?: StrapiEntity<LeisureData>
    }
  }
}

interface LeisureResponse {
  data: {
    id: number
    attributes: LeisureData
  }
}

// Ícones associados a cada campo
const attributeIcons: { [key: string]: [IconPrefix, IconName] } = {
  price: ['fas', 'dollar-sign'],
  totalArea: ['fas', 'chart-area'],
  usableArea: ['fas', 'ruler-combined'],
  neighborhood: ['fas', 'map-marker-alt'],
  region: ['fas', 'map-marker-alt'],
  numberOfBedrooms: ['fas', 'bed'],
  numberOfSuites: ['fas', 'bath'],
  socialBathroom: ['fas', 'toilet'],
  garage: ['fas', 'car'],
  iptu: ['fas', 'receipt'],
  workFund: ['fas', 'building'],
  condominiumFee: ['fas', 'building'],
  distanceFromBeach: ['fas', 'umbrella-beach'],
  seaView: ['fas', 'water'],
  status: ['fas', 'check-circle'],
  builderName: ['fas', 'hard-hat'],
  developerName: ['fas', 'hard-hat'],
  managerName: ['fas', 'user-tie'],
  managerPhone: ['fas', 'phone-alt'],
  caretakerName: ['fas', 'user-shield'],
  caretakerPhone: ['fas', 'phone-alt'],
  constructionYear: ['fas', 'calendar-alt'],
  deliverySchedule: ['fas', 'calendar-check'],
  numberOfServiceElevators: ['fas', 'cogs'],
  numberOfPassengerElevators: ['fas', 'cog'],
  Name: ['fas', 'building'],
  commercialStatus: ['fas', 'briefcase'],
  corner: ['fas', 'border-style'],
  guardhouse: ['fas', 'shield-alt'],
  street: ['fas', 'road'],
  number: ['fas', 'hashtag'],
  zipCode: ['fas', 'map-pin'],
  commonArea: ['fas', 'layer-group'],
  barter: ['fas', 'exchange-alt'],
  financing: ['fas', 'hand-holding-usd'],
  paymentMethod: ['fas', 'credit-card'],
  halfBath: ['fas', 'restroom'],
  bathrooms: ['fas', 'bath'],
  closet: ['fas', 'door-closed'],
  livingRoom: ['fas', 'couch'],
  kitchen: ['fas', 'utensils'],
  laundryRoom: ['fas', 'soap'],
  laundryArea: ['fas', 'soap'],
  pantry: ['fas', 'bread-slice'],
  intercom: ['fas', 'phone-volume'],
  elevator: ['fas', 'arrow-up'],
  furnished: ['fas', 'chair'],
  furnitureNotes: ['fas', 'sticky-note'],
  yearOfConstruction: ['fas', 'calendar'],
  keys: ['fas', 'key'],
  cabinets: ['fas', 'box-open'],
  conservation: ['fas', 'tools'],
  copa: ['fas', 'coffee'],
  classification: ['fas', 'layer-group'],
  front: ['fas', 'arrows-alt-h'],
  back: ['fas', 'arrows-alt-h'],
  rightSide: ['fas', 'arrows-alt-h'],
  leftSide: ['fas', 'arrows-alt-h'],
  leveled: ['fas', 'check'],
  fenced: ['fas', 'align-justify'],
  waterSupply: ['fas', 'tint'],
  electricitySupply: ['fas', 'bolt'],
  type: ['fas', 'house-user'],
  numberOfRooms: ['fas', 'door-open'],
  maidsQuarters: ['fas', 'bed'],
  WCEmployee: ['fas', 'bath'],
}

// Cabeçalho simples
function SimpleHeader() {
  return (
    <header className="no-print fixed left-0 right-0 top-0 z-50 flex h-16 items-center justify-center bg-green-800 px-4 shadow-md">
      <div className="flex items-center justify-center space-x-4">
        <Link to="/home">
          <img src={Logo} alt="logo" className="h-11 w-auto" />
        </Link>
      </div>
    </header>
  )
}

// Função que retorna o prefixo do tipo do imóvel
function getPropertyPrefix(propertyType?: string): string {
  switch (propertyType) {
    case 'house':
      return 'CA' // Casa
    case 'apartment':
      return 'AP' // Apartamento
    case 'commercial_property':
      return 'CO' // Comercial
    case 'area':
      return 'TE' // Terreno
    default:
      return 'IM' // genérico (Imóvel)
  }
}

const ownerLabels = {
  name: 'Nome',
  telephone: 'Telefone(s)',
  cell_phone: 'Celular(es)',
  email: 'E-mail(s)',
  observation: 'Observação',
}

function formatValue(value: unknown, key?: string): string {
  // Se for boolean, retorna "Sim" para true ou vazio para false
  if (typeof value === 'boolean') {
    return value ? 'Sim' : ''
  }

  // Se a chave for price, iptu ou condominiumFee, converte o valor para número e formata como moeda
  if (key === 'price' || key === 'iptu' || key === 'condominiumFee') {
    const numericValue =
      typeof value === 'number'
        ? value
        : parseFloat(String(value).replace(/[^0-9.-]+/g, ''))
    if (!isNaN(numericValue)) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(numericValue)
    }
  }

  // Se for número e não for um dos campos acima, formata com o locale
  if (typeof value === 'number') {
    return value.toLocaleString('pt-BR')
  }

  return String(value)
}

// Renderiza atributos com seus labels e ícones
function renderAttributes(
  attributes: Record<string, unknown>,
  labels: Record<string, string>,
) {
  // filtra só campos que têm valor
  const filledAttributes = Object.entries(attributes).filter(
    ([, v]) => v !== null && v !== undefined && v !== '' && v !== false,
  )

  if (filledAttributes.length === 0) return null

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
      {filledAttributes.map(([key, value]) => {
        const label = labels[key] || key
        const icon = attributeIcons[key] || ['fas', 'circle']
        const val = formatValue(value, key)
        if (!val) return null

        return (
          <div key={key} className="flex items-center space-x-3 break-words">
            <FontAwesomeIcon icon={icon} className="text-lg" />
            <div>
              <span className="font-semibold text-gray-800">{label}:</span>{' '}
              <span className="text-gray-700">{val}</span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export function Property() {
  const { id } = useParams<{ id: string }>()
  const [property, setProperty] = useState<PropertyStrapiData | null>(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalImage, setModalImage] = useState('')

  const openModal = (url: string) => {
    setModalImage(url)
    setModalOpen(true)
  }

  // Dados do condomínio
  const [condominiumData, setCondominiumData] =
    useState<CondominiumAttributes | null>(null)

  // Lazer do imóvel
  const [leisureData, setLeisureData] = useState<LeisureData | null>(null)
  const [leisurePhotos, setLeisurePhotos] = useState<string[]>([])

  // Lazer do condomínio
  const [condoLeisureData, setCondoLeisureData] = useState<LeisureData | null>(
    null,
  )
  const [condoLeisurePhotos, setCondoLeisurePhotos] = useState<string[]>([])

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const userCollaborator = localStorage.getItem('userCollaborator')
  const isAdmin = userCollaborator === 'ADMIN'

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const route = import.meta.env.VITE_URL_ENDPOINT
        // Carrega a property + populate
        const res = await fetch(
          `${route}/api/properties/${id}?populate=condominium,leisure,photos,owner,block`,
        )
        if (!res.ok) throw new Error('Falha ao carregar')
        const data: PropertyStrapiResponse = await res.json()
        setProperty(data.data)

        const condoId = data.data.attributes.condominium?.data?.id
        const propertyLeisureId = data.data.attributes.leisure?.data?.id

        // Se existir Condomínio, busca seu lazer
        if (condoId) {
          const condoRes = await fetch(
            `${route}/api/condominiums/${condoId}?populate=leisure`,
          )
          if (condoRes.ok) {
            const cData: CondominiumResponse = await condoRes.json()
            setCondominiumData(cData.data.attributes)

            // Lazer do condomínio
            const condoLeisureId = cData.data.attributes.leisure?.data?.id
            if (condoLeisureId) {
              const condoLeisureRes = await fetch(
                `${route}/api/leisures/${condoLeisureId}?populate=photos`,
              )
              if (condoLeisureRes.ok) {
                const cLeisureJson: LeisureResponse =
                  await condoLeisureRes.json()
                setCondoLeisureData(cLeisureJson.data.attributes)

                // Montar URLs das fotos do lazer do condomínio
                const cLeisurePhotos = cLeisureJson.data.attributes.photos
                if (
                  cLeisurePhotos &&
                  'data' in cLeisurePhotos &&
                  Array.isArray(cLeisurePhotos.data)
                ) {
                  const photoUrls = cLeisurePhotos.data.map(
                    (p) => route + p.attributes.url,
                  )
                  setCondoLeisurePhotos(photoUrls)
                }
              }
            }
          }
        }

        // Lazer do imóvel
        if (propertyLeisureId) {
          const leisureRes = await fetch(
            `${route}/api/leisures/${propertyLeisureId}?populate=photos`,
          )
          if (leisureRes.ok) {
            const lData: LeisureResponse = await leisureRes.json()
            setLeisureData(lData.data.attributes)

            // Montar URLs das fotos do lazer do imóvel
            const lPhotos = lData.data.attributes.photos
            if (lPhotos && 'data' in lPhotos && Array.isArray(lPhotos.data)) {
              const leisureUrls = lPhotos.data.map(
                (p) => route + p.attributes.url,
              )
              setLeisurePhotos(leisureUrls)
            }
          }
        }
      } catch (err) {
        setError('Erro ao carregar imóvel')
      } finally {
        setLoading(false)
      }
    }

    fetchProperty()
  }, [id])

  if (loading) {
    return (
      <>
        <SimpleHeader />
        <div className="mt-24 text-center text-gray-700">Carregando...</div>
      </>
    )
  }

  if (error) {
    return (
      <>
        <SimpleHeader />
        <div className="mt-24 text-center text-red-500">{error}</div>
      </>
    )
  }

  // Se não encontrar property
  if (!property) {
    return (
      <>
        <SimpleHeader />
        <div className="mt-24 text-center text-gray-700">
          Imóvel não encontrado.
        </div>
      </>
    )
  }

  // Atributos do imóvel
  const attributes = property.attributes
  const route = import.meta.env.VITE_URL_ENDPOINT

  // Fotos do imóvel
  const photos =
    attributes.photos?.data?.map((p) => route + p.attributes.url) ?? []

  // Última atualização
  const ultimaAtualizacao = attributes.updatedAt
    ? new Date(attributes.updatedAt).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    : 'Não disponível'

  const primeiraCriacao = attributes.createdAt
    ? new Date(attributes.createdAt).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    : 'Não disponível'

  // Função para gerar link do Google Maps (usada apenas fora do modo de impressão)
  const buildGoogleMapsUrl = (addr: {
    street?: string
    number?: string
    neighborhood?: string
    region?: string
  }) => {
    const query = encodeURIComponent(
      `${addr.street || ''}, ${addr.number || ''}, ${addr.neighborhood || ''}, ${
        addr.region || ''
      }`,
    )
    return `https://www.google.com/maps/search/?api=1&query=${query}`
  }

  // Print
  const handlePrint = () => {
    window.print()
  }

  // Download imagens
  const handleDownloadImages = async () => {
    const zip = new JSZip()
    const imgFolder = zip.folder('images')
    for (let i = 0; i < photos.length; i++) {
      const imageUrl = photos[i]
      const response = await fetch(imageUrl)
      const blob = await response.blob()
      const extension = imageUrl.substring(imageUrl.lastIndexOf('.')) || '.jpg'
      const filename = `image${i + 1}${extension}`
      imgFolder?.file(filename, blob)
    }
    const content = await zip.generateAsync({ type: 'blob' })
    saveAs(content, `imagens-imovel-${id}.zip`)
  }

  // CSS de impressão
  const printStyles = `
  @page {
    size: A4;
    margin: 10px;
  }

  @media print {
    /* Forçar tudo a caber em uma única página, reduzindo escala e fonte */
    html, body {
      width: 100%;
      margin: 0;
      padding: 0;
      line-height: 1.2;
    }
    body {
      transform: scale(0.85);
      transform-origin: top left;
      width: 100%;
      overflow: hidden;
    }

    /* Esconder cabeçalho fixo, carrosséis e botões */
    .no-print,
    .hide-print-photos,
    .hide-print-links {
      display: none !important;
    }

    /* Esconder especificamente as seções de fotos e links */
    .swiper,
    .print-thumbnails,
    .swiper-button-next,
    .swiper-button-prev,
    .swiper-pagination,
    .swiper-scrollbar,
    .photos-section,
    .link-section {
      display: none !important;
    }

    /* Ajusta possíveis quebras de página */
    .container,
    .min-h-screen {
      page-break-inside: avoid;
    }

    /* Diminui fontes gerais para caber em 1 página */
    * {
      font-size: 13px !important;
    }
  }
`

  // Labels do imóvel
  const labels = {
    city: 'Cidade',
    totalArea: 'Área Total (m²)',
    usableArea: 'Área Útil - Privativa (m²)',
    price: 'Valor do imóvel (R$)',
    neighborhood: 'Bairro',
    region: 'Região',
    numberOfBedrooms: 'Dormitório(s)',
    numberOfSuites: 'Suíte(s)',
    socialBathroom: 'Banheiro(s) Social',
    iptu: 'IPTU (R$)',
    workFund: 'Fundo de Obra (R$)',
    condominiumFee: 'Condomínio (R$)',
    distanceFromBeach: 'Distância da Praia (m)',
    seaView: 'Vista para o Mar',
    status: 'Status do Imóvel',
    commonArea: 'Área Comum (m²)',
    barter: 'Aceita Permuta',
    financing: 'Financiamento',
    paymentMethod: 'Condição Comercial',
    halfBath: 'Lavabo',
    bathrooms: 'Banheiro(s) (Total)',
    closet: 'Closet',
    livingRoom: 'Sala(s)',
    kitchen: 'Cozinha(s)',
    laundryRoom: 'Lavanderia',
    laundryArea: 'Área de Serviço',
    pantry: 'Despensa',
    maidsQuarters: 'Quarto de Empregada',
    intercom: 'Interfone',
    elevator: 'Elevador',
    furnished: 'Mobiliado',
    furnitureNotes: 'Observações Mobiliário',
    yearOfConstruction: 'Ano de Construção',
    keys: 'Chaves',
    cabinets: 'Armários',
    conservation: 'Conservação',
    guardhouse: 'Guarita no Imóvel',
    copa: 'Copa',
    classification: 'Classificação',
    front: 'Frente',
    back: 'Fundo',
    rightSide: 'Lado Direito',
    leftSide: 'Lado Esquerdo',
    corner: 'Esquina',
    leveled: 'Aterrado',
    fenced: 'Murado',
    waterSupply: 'Rede de Água',
    electricitySupply: 'Rede Elétrica',
    type: 'Tipo do Imóvel',
    numberOfRooms: 'Sala(s)',
    garage: 'Garagem',
    totalBathrooms: 'N° de banheiros',
    lado: 'Lado',
    captador: 'Captador',
    WCEmployee: 'WC Empregada',
    apartamentPosition: 'Posição do Apartamento',
  }

  // Labels do condomínio
  const condominiumLabels = {
    Name: 'Nome do Condomínio',
    commercialStatus: 'Status Comercial',
    constructionYear: 'Ano de Construção',
    developerName: 'Incorporadora',
    builderName: 'Construtora',
    managerName: 'Síndico',
    managerPhone: 'Telefone do Síndico',
    caretakerName: 'Zelador',
    caretakerPhone: 'Telefone do Zelador',
    deliverySchedule: 'Prazo de Entrega',
    numberOfServiceElevators: 'Nº Elevadores de Serviço',
    numberOfPassengerElevators: 'Nº Elevadores privativos',
    collectiveGas: 'Gas Coletivo',
    beachService: 'Serviço de praia',
    corner: 'Esquina',
    guardhouse: 'Guarita',
    beachEquipmentStorage: 'Deposito de Equipamentos de Praia',
    undergroundGarage: 'Garagem no subsolo',
  }

  if (
    attributes.propertyType === 'house' ||
    attributes.propertyType === 'terrain'
  ) {
    labels.usableArea = 'Área Construída (m²)'
    labels.totalArea = 'Área Terreno (m²)'
  }

  // Labels do lazer
  const leisureLabels: { [key: string]: string } = {
    partyHall: 'Salão de Festas',
    adultPool: 'Piscina Adulto',
    kidsPool: 'Piscina Infantil',
    gym: 'Espaço Fitness',
    sauna: 'Sauna',
    playground: 'Playground',
    sportsCourt: 'Quadra Poliesportiva',
    tennisCourt: 'Quadra de Tênis',
    bbqArea: 'Churrasqueira',
    cinemaRoom: 'Cinema',
    socialHall: 'Salão Social',
    gameRoom: 'Salão de jogos',
    squashCourt: 'Quadra de Squash',
    wetDeckPool: 'Piscina com Deck Molhado',
    lapPool: 'Piscina de Raia',
    kidsBeachPool: 'Piscina Infantil com Praia',
    solarium: 'Solarium',
    poolBar: 'Bar na Piscina',
    massageRoom: 'Sala de Massagem',
    womenSpace: 'Espaço Mulher',
    petPlace: 'Pet Place',
    youthGameRoom: 'Salão de jogos Juvenil',
    toyLibrary: 'Brinquedoteca',
    pilatesSpace: 'Espaço Pilates',
    lounge: 'Lounge',
    gourmetSpace: 'Espaço Gourmet',
    kidsPartyRoom: 'Salão de Festas Infantil',
    adultPartyRoomWithBar: 'Salão de Festas Adulto com Bar',
    adultGameRoom: 'Salão de jogos Adulto',
    readingRoom: 'Sala de Leitura',
    hammockArea: 'Área de Descanso (Redário)',
    zenSpace: 'Espaço Zen',
    coworkingSpace: 'Espaço Coworking',
    adultTVRoom: 'Sala de TV Adulto',
    gourmetBar: 'Espaço Gourmet com Bar',
    saunaSeca: 'Sauna Seca',
    saunaUmida: 'Sauna Umida',
    saunaComDescanso: 'Sauna Com Descanso',
    varanda: 'Varanda',
    terraco: 'Terraço',
    outros: 'Outro(s)',
  }

  let filteredLeisure: { [key: string]: unknown } = {}
  let leisureOutros = ''
  if (leisureData) {
    const { outros, ...restLeisure } = leisureData as unknown as Record<
      string,
      unknown
    >
    leisureOutros = outros ? String(outros) : ''
    filteredLeisure = Object.fromEntries(
      Object.entries(restLeisure).filter(([, v]) => v === true),
    )
  }

  // Filtra as flags de lazer do condomínio
  let filteredCondoLeisure: { [key: string]: unknown } = {}
  if (condoLeisureData) {
    const cLeisureWithoutProperty = { ...condoLeisureData } as Record<
      string,
      unknown
    >
    delete cLeisureWithoutProperty.property
    delete cLeisureWithoutProperty.photos
    filteredCondoLeisure = Object.fromEntries(
      Object.entries(cLeisureWithoutProperty).filter(([, v]) => v === true),
    )
  }

  // Filtro de dados do condomínio (remove timestamps e leisure)
  const filteredCondoData = Object.fromEntries(
    Object.entries(condominiumData ?? {}).filter(
      ([key]) =>
        key !== 'createdAt' &&
        key !== 'updatedAt' &&
        key !== 'publishedAt' &&
        key !== 'region' &&
        key !== 'street' &&
        key !== 'neighborhood' &&
        key !== 'number' &&
        key !== 'zipCode' &&
        key !== 'deliverySchedule' &&
        key !== 'leisure',
    ),
  )

  const orderedCondoData = {
    Name: filteredCondoData.Name,
    commercialStatus: filteredCondoData.commercialStatus,
    constructionYear: filteredCondoData.constructionYear,
    managerName: filteredCondoData.managerName,
    caretakerName: filteredCondoData.caretakerName,
    builderName: filteredCondoData.builderName,
    managerPhone: filteredCondoData.managerPhone,
    caretakerPhone: filteredCondoData.caretakerPhone,
    developerName: filteredCondoData.developerName,
    deliverySchedule: filteredCondoData.deliverySchedule,
    numberOfServiceElevators: filteredCondoData.numberOfServiceElevators,
    numberOfPassengerElevators: filteredCondoData.numberOfPassengerElevators,
    collectiveGas: filteredCondoData.collectiveGas,
    beachService: filteredCondoData.beachService,
    corner: filteredCondoData.corner,
    guardhouse: filteredCondoData.guardhouse,
    undergroundGarage: filteredCondoData.undergroundGarage,
    beachEquipmentStorage: filteredCondoData.beachEquipmentStorage,
  }

  // Decide qual prefixo vai exibir baseado no propertyType
  const propertyPrefix = getPropertyPrefix(attributes.propertyType)

  // Definir quais campos já foram tratados em sections
  const shownFields = new Set([
    'price',
    'totalArea',
    'iptu',
    'condominiumFee',
    'distanceFromBeach',
    'commonArea',
    'usableArea',
    'totalArea',
    'street',
    'number',
    'neighborhood',
    'region',
    'updatedAt',
    'owner',
    'photos',
    'leisure',
    'condominium',
    'block',
    'propertyType',
  ])

  // Resto dos campos
  const allPropertyKeys = Object.keys(attributes)
  const otherFields: Record<string, unknown> = {}
  for (const key of allPropertyKeys) {
    if (!shownFields.has(key)) {
      otherFields[key] = attributes[key as keyof PropertyAttributes]
    }
  }

  // Sessões específicas
  // 1) Campos financeiros
  const financialFields: Record<string, unknown> = {}
  for (const f of [
    'price',
    'iptu',
    'status',
    'barter',
    'condominiumFee',
    'paymentMethod',
    'financing',
    'workFund',
  ]) {
    if (f in attributes) {
      financialFields[f] = attributes[f as keyof PropertyAttributes]
    }
  }

  // 2) Campos de cômodos
  const roomFields: Record<string, unknown> = {}
  // 3) Campos de estrutura
  const structureFields: Record<string, unknown> = {}
  // 4) Campos de área (se propertyType === 'area')
  const areaFields: Record<string, unknown> = {}

  const terrainFields: Record<string, unknown> = {}

  for (const f of [
    'numberOfBedrooms',
    'numberOfRooms',
    'kitchen',
    'laundryArea',
    'numberOfSuites',
    'halfBath',
    'bathrooms',
    'socialBathroom',
    'closet',
    'livingRoom',
    'laundryRoom',
    'pantry',
    'maidsQuarters',
    'WCEmployee',
  ]) {
    if (f in otherFields) {
      roomFields[f] = otherFields[f]
      delete otherFields[f]
    }
  }

  // Agora, calcule o total de banheiros com os valores já preenchidos em roomFields
  const closetBathrooms = Number(attributes.numberOfSuites) || 0
  const privateBathrooms = Number(attributes.socialBathroom) || 0
  const lavabos = Number(attributes.halfBath) || 0
  const totalBathrooms = closetBathrooms + privateBathrooms + lavabos
  if (totalBathrooms > 0) {
    roomFields.totalBathrooms = totalBathrooms
    labels.totalBathrooms = 'N° de banheiros'
  }

  for (const f of [
    'yearOfConstruction',
    'keys',
    'cabinets',
    'conservation',
    'guardhouse',
    'copa',
    'intercom',
    'elevator',
    'furnished',
    'furnitureNotes',
  ]) {
    if (f in otherFields) {
      structureFields[f] = otherFields[f]
      delete otherFields[f]
    }
  }

  if (attributes.propertyType === 'area') {
    for (const f of [
      'classification',
      'type',
      'front',
      'back',
      'rightSide',
      'leftSide',
      'corner',
      'leveled',
      'fenced',
      'waterSupply',
      'electricitySupply',
    ]) {
      if (f in otherFields) {
        areaFields[f] = otherFields[f]
        delete otherFields[f]
      }
    }
  }

  if (attributes.propertyType === 'terrain') {
    for (const f of [
      'classification',
      'type',
      'front',
      'back',
      'rightSide',
      'leftSide',
      'corner',
      'leveled',
      'fenced',
      'waterSupply',
      'electricitySupply',
    ]) {
      if (f in otherFields) {
        terrainFields[f] = otherFields[f]
        delete otherFields[f]
      }
    }
  }

  const apNumber = attributes.apartmentNumber
    ? `- Ap. ${attributes.apartmentNumber}`
    : ''

  const Cond = attributes.condominium?.data?.attributes?.Name
    ? '- ' + attributes.condominium?.data?.attributes?.Name
    : ''

  const Block = attributes.block?.data?.attributes?.name
    ? '- ' + attributes.block?.data?.attributes?.name
    : ''

  return (
    <>
      <style>{printStyles}</style>

      {modalOpen && (
        <div
          // Overlay em toda a tela
          onClick={() => setModalOpen(false)}
          className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-90"
        >
          {/* Div interna para conter a imagem e o botão. 
        Impede clique no próprio conteúdo de fechar o modal */}
          <div
            onClick={(e) => e.stopPropagation()}
            className="max-w-screen relative max-h-screen"
          >
            <img
              src={modalImage}
              alt="Imagem ampliada"
              className="h-auto max-h-screen w-auto object-contain"
            />
            <img
              src={Logo}
              alt="Logo Iguatemar"
              className="pointer-events-none absolute inset-0 m-auto h-32 w-32 opacity-75"
            />
            <button
              onClick={() => setModalOpen(false)}
              className="absolute right-4 top-4 rounded-full bg-gray-800 px-3 py-1 text-3xl font-bold text-white"
            >
              X
            </button>
          </div>
        </div>
      )}

      {/* Thumbnails para impressão (por padrão escondidas) */}
      {photos.length > 0 && (
        <div className="print-thumbnails hidden">
          {photos.map((url, idx) => (
            <img
              key={idx}
              src={url}
              alt={`Foto ${idx + 1}`}
              className="h-auto w-24 object-cover"
            />
          ))}
        </div>
      )}
      {!modalOpen && <SimpleHeader />}

      <div className="min-h-screen pt-20 font-sans">
        {/* Carrossel de fotos */}
        <div className="no-print photos-section relative w-full overflow-hidden">
          {photos.length > 0 ? (
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={10}
              slidesPerView={2}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              className="w-full"
              breakpoints={{
                640: { slidesPerView: 2, spaceBetween: 10 },
                768: { slidesPerView: 2, spaceBetween: 15 },
                1024: { slidesPerView: 3, spaceBetween: 20 },
              }}
            >
              {attributes.video && (
                <SwiperSlide>
                  <div className="relative h-64 w-full cursor-pointer sm:h-80 md:h-96">
                    <iframe
                      src={`https://www.youtube.com/embed/${attributes.video}`}
                      title="Vídeo do imóvel"
                      allowFullScreen
                      className="h-full w-full"
                    />
                  </div>
                </SwiperSlide>
              )}
              {photos.map((url, idx) => (
                <SwiperSlide key={idx}>
                  <div
                    className="relative h-64 w-full cursor-pointer sm:h-80 md:h-96"
                    onClick={() => openModal(url)}
                  >
                    <img
                      src={url}
                      alt={`Foto ${idx + 1}`}
                      className="h-full w-full object-cover"
                      loading="lazy"
                    />
                    {/* Logo sobreposta em cada imagem */}
                    <img
                      src={Logo}
                      alt="Logo Iguatemar"
                      className="pointer-events-none absolute inset-0 m-auto h-32 w-32 opacity-60"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <div className="flex h-64 items-center justify-center bg-gray-200 text-gray-500">
              Sem imagem disponível
            </div>
          )}
        </div>

        <div className="container mx-auto px-4 pt-6">
          {/* Header e botões de ação */}
          <div className="mb-6 flex flex-col items-start justify-between gap-4 border-b pb-4 sm:flex-row sm:items-center">
            <h1 className="flex items-center gap-2 text-2xl font-bold text-gray-800 sm:text-3xl">
              <FontAwesomeIcon
                icon={['fas', 'home']}
                className="text-green-700"
              />
              {propertyPrefix} #{property.id}
            </h1>
            <div className="no-print flex flex-wrap gap-2">
              {/* Botão para Google Maps (hide-print-links) */}
              <button
                onClick={() => {
                  const mapsUrl = buildGoogleMapsUrl({
                    street: attributes.street,
                    number: attributes.number,
                    neighborhood: attributes.neighborhood,
                    region: attributes.region,
                  })
                  window.open(mapsUrl, '_blank')
                }}
                className="hide-print-links flex items-center gap-2 rounded bg-red-800 px-4 py-2 text-sm text-white transition hover:bg-red-900"
              >
                <FontAwesomeIcon icon={['fas', 'map-marker-alt']} />
                Ver no Google Maps
              </button>

              {/* Botão de imprimir */}
              <button
                onClick={handlePrint}
                className="hide-print-links flex items-center gap-2 rounded bg-blue-600 px-4 py-2 text-sm text-white transition hover:bg-blue-700"
              >
                <FontAwesomeIcon icon={['fas', 'print']} />
                Imprimir
              </button>

              {/* Botão de baixar imagens */}
              {photos.length > 0 && (
                <button
                  onClick={handleDownloadImages}
                  className="hide-print-links flex items-center gap-2 rounded bg-gray-600 px-4 py-2 text-sm text-white transition hover:bg-gray-700"
                >
                  <FontAwesomeIcon icon={['fas', 'download']} />
                  Baixar Imagens (.zip)
                </button>
              )}

              {isAdmin && (
                <Link to={`/editar/propriedade/${id}`}>
                  <button className="hide-print-links flex items-center gap-2 rounded bg-green-800 px-4 py-2 text-sm text-white transition hover:bg-green-900">
                    Editar Imóvel
                  </button>
                </Link>
              )}
            </div>
          </div>
          <p className="mb-2 text-sm text-gray-500">
            Criado em: {primeiraCriacao}{' '}
            {attributes.criador && `- ${attributes.criador}`}
          </p>{' '}
          <p className="mb-2 text-sm text-gray-500">
            Última atualização: {ultimaAtualizacao}{' '}
            {attributes.lastEditor && `- ${attributes.lastEditor}`}
          </p>
          <p className="mb-6 flex items-center gap-1 text-sm text-gray-600">
            <FontAwesomeIcon
              icon={['fas', 'map-marker-alt']}
              className="text-red-600"
            />
            {`${attributes.street}, ${attributes.number} - ${attributes.neighborhood}, ${attributes.region}`}
            {attributes.quadra ? ` - Quadra ${attributes.quadra}` : ''}
            {attributes.lote ? ` - Lote(s) ${attributes.lote}` : ''}
            {` - ${attributes.zipCode}, ${attributes.city} ${apNumber} ${Cond} ${Block}`}
          </p>
          {/* Seção de valores do Imóvel */}
          {renderAttributes(financialFields, labels) && (
            <div className="mt-8 border-t pt-6">
              <h2 className="mb-4 flex items-center gap-2 text-lg font-semibold text-gray-800 sm:text-xl">
                <FontAwesomeIcon
                  icon={['fas', 'money-bill-wave']}
                  className="text-green-700"
                />
                Informações Financeiras
              </h2>
              {renderAttributes(financialFields, labels)}
            </div>
          )}
          {/* Observações (Internas/Externas) */}
          {(attributes.internalObservation ||
            attributes.externalObservation) && (
            <div className="mt-8 border-t pt-6">
              <h2 className="mb-4 flex items-center gap-2 text-lg font-semibold text-gray-800 sm:text-xl">
                <FontAwesomeIcon
                  icon={['fas', 'info']}
                  className="text-green-500"
                />
                Observação
              </h2>
              {attributes.internalObservation && (
                <div className="mb-4">
                  <h3 className="mb-2 text-base font-semibold text-gray-700">
                    Observações Internas
                  </h3>
                  <p className="whitespace-pre-line text-gray-700">
                    {attributes.internalObservation}
                  </p>
                </div>
              )}
              {attributes.externalObservation && (
                <div>
                  <h3 className="mb-2 text-base font-semibold text-gray-700">
                    Observações Externas
                  </h3>
                  <p className="whitespace-pre-line text-gray-700">
                    {attributes.externalObservation}
                  </p>
                </div>
              )}
            </div>
          )}
          {/* Características do Imóvel */}
          <div className="mt-8 border-t pt-6">
            <h2 className="mb-4 flex items-center gap-2 text-lg font-semibold text-gray-800 sm:text-xl">
              <FontAwesomeIcon
                icon={['fas', 'list']}
                className="text-yellow-500"
              />
              Características do Imóvel
            </h2>

            {renderAttributes(
              {
                type: attributes.type,
                distanceFromBeach: attributes.distanceFromBeach,
                garage: attributes.garage,
                commonArea: attributes.commonArea,
                usableArea: attributes.usableArea,
                totalArea: attributes.totalArea,
                yearOfConstruction: attributes.yearOfConstruction,
                keys: attributes.keys,
                cabinets: attributes.cabinets,
                conservation: attributes.conservation,
                copa: attributes.copa,
                furnished: attributes.furnished,
                intercom: attributes.intercom,
                elevador: attributes.elevator,
                furnitureNotes: attributes.furnitureNotes,
                lado: attributes.lado,
                apartamentPosition: attributes.apartamentPosition,
                captador: attributes.captador,
              },
              labels,
            ) || (
              <p className="text-gray-500">Nenhuma característica adicional.</p>
            )}
          </div>
          {(() => {
            const renderedRoomFields = renderAttributes(roomFields, labels)
            return renderedRoomFields ? (
              <div className="mt-8 border-t pt-6">
                <h2 className="mb-4 flex items-center gap-2 text-lg font-semibold text-gray-800 sm:text-xl">
                  <FontAwesomeIcon
                    icon={['fas', 'couch']}
                    className="text-purple-500"
                  />
                  Ambientes
                </h2>
                {renderedRoomFields}
              </div>
            ) : null
          })()}
          {/* Características da Área (se for propertyType === 'area') */}
          {attributes.propertyType === 'area' && (
            <div className="mt-8 border-t pt-6">
              <h2 className="mb-4 flex items-center gap-2 text-lg font-semibold text-gray-800 sm:text-xl">
                <FontAwesomeIcon
                  icon={['fas', 'layer-group']}
                  className="text-blue-500"
                />
                Características da Área
              </h2>
              {renderAttributes(areaFields, labels) || (
                <p className="text-gray-500">Nenhuma característica de área.</p>
              )}
            </div>
          )}
          {/* Características da Área (se for propertyType === 'area') */}
          {attributes.propertyType === 'terrain' && (
            <div className="mt-8 border-t pt-6">
              <h2 className="mb-4 flex items-center gap-2 text-lg font-semibold text-gray-800 sm:text-xl">
                <FontAwesomeIcon
                  icon={['fas', 'layer-group']}
                  className="text-blue-500"
                />
                Características do Terreno
              </h2>
              {renderAttributes(terrainFields, labels) || (
                <p className="text-gray-500">
                  Nenhuma característica de terreno.
                </p>
              )}
            </div>
          )}
          {/* Detalhes do Condomínio (se existir) */}
          {condominiumData &&
            Object.values(filteredCondoData).some((val) => val) && (
              <div className="mt-8 border-t pt-6">
                <h2 className="mb-4 flex items-center gap-2 text-lg font-semibold text-gray-800 sm:text-xl">
                  <FontAwesomeIcon
                    icon={['fas', 'building']}
                    className="text-indigo-500"
                  />
                  Detalhes do Condomínio
                </h2>
                {renderAttributes(
                  orderedCondoData as Record<string, unknown>,
                  condominiumLabels,
                ) || (
                  <p className="text-gray-500">
                    Nenhum detalhe de condomínio disponível.
                  </p>
                )}
              </div>
            )}
          {attributes.owner?.data && (
            <div className="mt-8 border-t pt-6">
              <h2 className="mb-4 flex items-center gap-2 text-lg font-semibold text-gray-800 sm:text-xl">
                <FontAwesomeIcon
                  icon={['fas', 'user']}
                  className="text-indigo-500"
                />
                Dados do Proprietário
              </h2>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
                {Object.entries(attributes.owner.data.attributes)
                  .filter(
                    ([key]) =>
                      !['updatedAt', 'publishedAt', 'createdAt'].includes(key),
                  )
                  .map(([key, value]) => {
                    const labelKey = key as keyof typeof ownerLabels
                    return (
                      <div
                        key={key}
                        className="flex items-center space-x-3 break-words"
                      >
                        <FontAwesomeIcon
                          icon={attributeIcons[key] || ['fas', 'circle']}
                          className="text-lg"
                        />
                        <div>
                          <span className="font-semibold text-gray-800">
                            {ownerLabels[labelKey] || key}:
                          </span>{' '}
                          <span className="text-gray-700">
                            {formatValue(value)}
                          </span>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          )}
          {/* Lazer do Condomínio (se existir) */}
          {condoLeisureData && Object.keys(filteredCondoLeisure).length > 0 && (
            <div className="mt-8 border-t pt-6 ">
              <h2 className="mb-4 flex items-center gap-2 text-lg font-semibold text-gray-800 sm:text-xl">
                <FontAwesomeIcon
                  icon={['fas', 'swimmer']}
                  className="text-teal-500"
                />
                Lazer do Condomínio
              </h2>
              <ul className="grid list-disc grid-cols-1 space-y-2 pl-5 text-gray-700 sm:grid-cols-2 md:grid-cols-3">
                {Object.keys(filteredLeisure).map((itemKey) => (
                  <li key={itemKey} className="flex items-center">
                    <FontAwesomeIcon
                      icon={['fas', 'check']}
                      className="mr-2 text-green-500"
                    />
                    {leisureLabels[itemKey] || itemKey}
                  </li>
                ))}
                {leisureOutros && (
                  <li className="flex items-center">
                    <FontAwesomeIcon
                      icon={['fas', 'check']}
                      className="mr-2 text-green-500"
                    />
                    {leisureLabels.outros || 'Outros'}: {leisureOutros}
                  </li>
                )}
              </ul>

              {condoLeisurePhotos.length > 0 && (
                <div className="no-print photos-section mt-6">
                  <h3 className="mb-2 flex items-center gap-2 text-base font-semibold text-gray-800 sm:text-lg">
                    <FontAwesomeIcon
                      icon={['fas', 'camera']}
                      className="text-gray-700"
                    />
                    Fotos de Lazer do Condomínio
                  </h3>
                  <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={10}
                    slidesPerView={2}
                    navigation
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    className="w-full"
                    breakpoints={{
                      640: { slidesPerView: 2, spaceBetween: 10 },
                      768: { slidesPerView: 2, spaceBetween: 15 },
                      1024: { slidesPerView: 3, spaceBetween: 20 },
                    }}
                  >
                    {condoLeisurePhotos.map((url, idx) => (
                      <SwiperSlide key={idx}>
                        <div
                          className="relative h-64 w-full cursor-pointer sm:h-80 md:h-96"
                          onClick={() => openModal(url)}
                        >
                          <img
                            src={url}
                            alt={`Foto do Lazer do Condomínio ${idx + 1}`}
                            className="h-full w-full object-cover"
                            loading="lazy"
                          />
                          <img
                            src={Logo}
                            alt="Logo Iguatemar"
                            className="pointer-events-none absolute inset-0 m-auto h-32 w-32 opacity-60"
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              )}
            </div>
          )}
          {leisureData && Object.keys(filteredLeisure).length > 0 && (
            <div className="mt-8 border-t pt-6">
              <h2 className="mb-4 flex items-center gap-2 text-lg font-semibold text-gray-800 sm:text-xl">
                <FontAwesomeIcon
                  icon={['fas', 'swimmer']}
                  className="text-teal-500"
                />
                Lazer do Imóvel
              </h2>
              <ul className="grid list-disc grid-cols-1 space-y-2 pl-5 text-gray-700 sm:grid-cols-2 md:grid-cols-3">
                {Object.keys(filteredLeisure).map((itemKey) => (
                  <li key={itemKey} className="flex items-center">
                    <FontAwesomeIcon
                      icon={['fas', 'check']}
                      className="mr-2 text-green-500"
                    />
                    {leisureLabels[itemKey] || itemKey}
                  </li>
                ))}
              </ul>

              {leisurePhotos.length > 0 && (
                <div className="no-print photos-section mt-6">
                  <h3 className="mb-2 flex items-center gap-2 text-base font-semibold text-gray-800 sm:text-lg">
                    <FontAwesomeIcon
                      icon={['fas', 'camera']}
                      className="text-gray-700"
                    />
                    Fotos de Lazer do Imóvel
                  </h3>
                  <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={10}
                    slidesPerView={2}
                    navigation
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    className="w-full"
                    breakpoints={{
                      640: { slidesPerView: 2, spaceBetween: 10 },
                      768: { slidesPerView: 2, spaceBetween: 15 },
                      1024: { slidesPerView: 3, spaceBetween: 20 },
                    }}
                  >
                    {leisurePhotos.map((url, idx) => (
                      <SwiperSlide key={idx}>
                        <div
                          className="relative h-64 w-full cursor-pointer sm:h-80 md:h-96"
                          onClick={() => openModal(url)}
                        >
                          <img
                            src={url}
                            alt={`Foto de Lazer do Imóvel ${idx + 1}`}
                            className="h-full w-full object-cover"
                            loading="lazy"
                          />
                          <img
                            src={Logo}
                            alt="Logo Iguatemar"
                            className="pointer-events-none absolute inset-0 m-auto h-32 w-32 opacity-60"
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  )
}
