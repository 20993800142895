// Apartment.tsx

import React from 'react'

import { ApartmentData } from '@/components/form-property/property-types'
import { Checkbox } from '@/components/ui/checkbox'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

interface ApartmentProps {
  data: ApartmentData
  onChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => void
  onCheckboxChange: (
    name: keyof ApartmentData,
    checked: boolean | 'indeterminate',
  ) => void
}

export function Apartment({
  data,
  onChange,
  onCheckboxChange,
}: ApartmentProps) {
  const handleSelectChange = (value: string) => {
    const event = {
      target: {
        name: 'type',
        value,
      },
    } as React.ChangeEvent<HTMLInputElement>
    onChange(event)
  }

  return (
    <div className="flex h-full w-full flex-col bg-gray-100 p-8">
      <h1 className="mb-6 text-center text-3xl font-bold text-gray-800">
        Detalhes da Propriedade
      </h1>

      <div className="rounded-lg bg-white p-6 shadow-md">
        <Label htmlFor="type" className="text-gray-600">
          Classificação
        </Label>
        <Select value={data.type || ''} onValueChange={handleSelectChange}>
          <SelectTrigger
            id="type"
            name="type"
            className="mb-4 mt-1 w-1/4 rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          >
            <SelectValue placeholder="Classificação" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="Apartamento">Apartamento</SelectItem>
            <SelectItem value="Flat">Flat</SelectItem>
            <SelectItem value="Cobertura">Cobertura</SelectItem>
            <SelectItem value="Gardem">Gardem</SelectItem>
            <SelectItem value="Triplex">Triplex</SelectItem>
            <SelectItem value="Kitnet">Kitnet</SelectItem>
            <SelectItem value="Penthouse">Penthouse</SelectItem>
            <SelectItem value="Studio">Studio</SelectItem>
          </SelectContent>
        </Select>

        <div className="mb-5 flex w-64 flex-col">
          <Label htmlFor="apartamentPosition" className="text-gray-600">
            Posição do Apartamento
          </Label>
          <Select
            value={data.apartamentPosition || ''}
            onValueChange={(value: string) =>
              onChange({
                target: { name: 'apartamentPosition', value },
              } as React.ChangeEvent<HTMLInputElement>)
            }
          >
            <SelectTrigger
              id="apartamentPosition"
              name="apartamentPosition"
              className="mt-1"
            >
              <SelectValue placeholder="Escolha" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Frente">Frente</SelectItem>
              <SelectItem value="Lateral">Lateral</SelectItem>
              <SelectItem value="Fundos">Fundos</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          <div>
            <Label htmlFor="numberOfBedrooms" className="text-gray-600">
              Dormitório(s)
            </Label>
            <Input
              type="number"
              id="numberOfBedrooms"
              name="numberOfBedrooms"
              placeholder="Dormitórios"
              value={data.numberOfBedrooms}
              onChange={onChange}
              className="mt-1 block w-full"
            />
          </div>

          <div>
            <Label htmlFor="numberOfSuites" className="text-gray-600">
              Sendo Suíte
            </Label>
            <Input
              type="number"
              id="numberOfSuites"
              name="numberOfSuites"
              placeholder="Sendo Suite"
              value={data.numberOfSuites}
              onChange={onChange}
              className="mt-1 block w-full"
            />
          </div>

          <div>
            <Label htmlFor="numberOfRooms" className="text-gray-600">
              Sala(s)
            </Label>
            <Input
              type="number"
              id="numberOfRooms"
              name="numberOfRooms"
              placeholder="Número de Salas"
              value={data.numberOfRooms}
              onChange={onChange}
              className="mt-1 block w-full"
            />
          </div>

          <div>
            <Label htmlFor="socialBathroom" className="text-gray-600">
              Banheiro Social
            </Label>
            <Input
              type="number"
              id="socialBathroom"
              name="socialBathroom"
              placeholder="Banheiro Social"
              value={data.socialBathroom}
              onChange={onChange}
              className="mt-1 block w-full"
            />
          </div>

          <div>
            <Label htmlFor="halfBath" className="text-gray-600">
              Lavabo
            </Label>
            <Input
              type="number"
              id="halfBath"
              name="halfBath"
              placeholder="Lavabo"
              value={data.halfBath}
              onChange={onChange}
              className="mt-1 block w-full"
            />
          </div>

          <div>
            <Label htmlFor="kitchen" className="text-gray-600">
              Cozinha(s)
            </Label>
            <Input
              type="text"
              id="kitchen"
              name="kitchen"
              placeholder="Cozinha"
              value={data.kitchen}
              onChange={onChange}
              className="mt-1 block w-full"
            />
          </div>

          <div>
            <Label htmlFor="apartmentNumber" className="text-gray-600">
              Número do Apartamento
            </Label>
            <Input
              type="text"
              id="apartmentNumber"
              name="apartmentNumber"
              placeholder="Número do Apartamento"
              value={data.apartmentNumber}
              onChange={onChange}
              className="mt-1 block w-full"
            />
          </div>

          <div>
            <Label htmlFor="garage" className="text-gray-600">
              Vaga(s) de Garagem
            </Label>
            <Input
              type="text"
              id="garage"
              name="garage"
              placeholder="Garagem"
              value={data.garage}
              onChange={onChange}
              className="mt-1 block w-full"
            />
          </div>
        </div>

        <h2 className="mb-4 mt-6 text-xl font-semibold text-gray-700">
          Adicionais
        </h2>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          <div className="flex items-center space-x-2">
            <Checkbox
              id="intercom"
              name="intercom"
              checked={data.intercom}
              onCheckedChange={(checked) =>
                onCheckboxChange('intercom', checked)
              }
            />
            <Label htmlFor="intercom" className="text-gray-600">
              Interfone
            </Label>
          </div>

          <div className="flex items-center space-x-2">
            <Checkbox
              id="maidsQuarters"
              name="maidsQuarters"
              checked={data.maidsQuarters}
              onCheckedChange={(checked) =>
                onCheckboxChange('maidsQuarters', checked)
              }
            />
            <Label htmlFor="maidsQuarters" className="text-gray-600">
              Dependência de empregado
            </Label>
          </div>

          <div className="flex items-center space-x-2">
            <Checkbox
              id="WCEmployee"
              name="WCEmployee"
              checked={data.WCEmployee}
              onCheckedChange={(checked) =>
                onCheckboxChange('WCEmployee', checked)
              }
            />
            <Label htmlFor="maidsQuarters" className="text-gray-600">
              WC de empregado
            </Label>
          </div>

          <div className="flex items-center space-x-2">
            <Checkbox
              id="laundryArea"
              name="laundryArea"
              checked={data.laundryArea}
              onCheckedChange={(checked) =>
                onCheckboxChange('laundryArea', checked)
              }
            />
            <Label htmlFor="laundryArea" className="text-gray-600">
              Área de Serviço
            </Label>
          </div>

          <div className="flex items-center space-x-2">
            <Checkbox
              id="elevator"
              name="elevator"
              checked={data.elevator}
              onCheckedChange={(checked) =>
                onCheckboxChange('elevator', checked)
              }
            />
            <Label htmlFor="elevator" className="text-gray-600">
              Elevador
            </Label>
          </div>
        </div>

        <h2 className="mb-4 mt-6 text-xl font-semibold text-gray-700">
          Mobiliado
        </h2>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div className="flex items-center space-x-2">
            <Checkbox
              id="furnished"
              name="furnished"
              checked={data.furnished}
              onCheckedChange={(checked) =>
                onCheckboxChange('furnished', checked)
              }
            />
            <Label htmlFor="furnished" className="text-gray-600">
              Mobiliado
            </Label>
          </div>

          <div>
            <Label htmlFor="furnitureNotes" className="text-gray-600">
              Notas sobre Móveis
            </Label>
            <Input
              type="text"
              id="furnitureNotes"
              name="furnitureNotes"
              placeholder="Notas sobre os móveis"
              value={data.furnitureNotes}
              onChange={onChange}
              className="mt-1 block w-full"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
